<template>
  <div class="vh-100 d-flex flex-column align-items-center">
    <h3 class="pt-2">Atendimento via WhatsApp</h3>

    <p class="text-center px-3 w-75 pb-1" v-if="opportunity.contact.phone_number">
      Você será direcionado para o WhatsApp dessa oportunidade.
    </p>

    <p class="text-center px-3 w-75 pb-1" v-else>
      Atualmente essa oportunidade não possui WhatsApp integrado.
    </p>

    <b-button v-if="opportunity.contact.phone_number" @click="goToWhatsapp()" class="whatsapp-chat-button">
      <div>
        <img src="@/assets/images/icons/whatsapp-chat-icon-green.svg" />
      </div>
      Ir para o WhatsApp
    </b-button>

    <img v-if="opportunity.contact.phone_number" src="@/assets/images/whatsapp-illustration.svg"
      class="w-100 mx-auto mt-2" />
    <img v-else src="@/assets/images/whatsapp-illustration-gray.svg" class="w-100 mx-auto mt-3" />
  </div>
</template>
<script>
import { BButton } from "bootstrap-vue";
import { openWhatsappChannel } from "@/services/callService";

export default {
  components: {
    BButton,
  },

  props: {
    opportunity: {
      type: Object,
      default: null,
      required: true,
    },
  },

  computed: {
    seller() {
      return this.$store.getters["app/getUserData"];
    },

    currentWorkspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },
  },

  methods: {
    goToWhatsapp() {
      const informations = {
        seller_name: this.getFirstName(this.seller.name),
        opportunity: this.opportunity,
        workspace: this.currentWorkspace.name
      };

      openWhatsappChannel(informations);
      this.$emit("whats");
    },

    getFirstName(userName) {
      return this.$service.toCapitalize(
        this.$service.splitFirstName(userName)
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.whatsapp-chat-button {
  background: #2ed47a !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;

  div {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    margin-right: 7px;

    img {
      width: 22px;
    }
  }
}
</style>
