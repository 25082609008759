<template>
  <div
    class="line"
    :style="{ 'background': color }"
  />
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: '#000',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .line {
    height: 1px;
    width: 100%;
  }
</style>
