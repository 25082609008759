<template>
  <ValidationProvider
    v-slot="{ errors, required }"
    tag="div"
    :vid="vid"
    :name="inputName"
    :rules="!isDisabled ? rules : ''"
  >
    <VueCtkDateTimePicker
      ref="input"
      v-model="innerValue"
      class="ctk-date-time-picker"
      :class="{ error: errors[0], default: !errors[0], disabled: isDisabled, right: right }"
      :maxlength="maxLength"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :label="placeholder"
      :only-date="dateOnly"
      :only-time="timeOnly"
      :format="format"
      :minDate="minDateFormatted"
      :maxDate="maxDateFormatted"
      :formatted="formatted"
      :no-button-now="noButtonNow"
      :minuteInterval="minuteInterval"
      :right="right"
      buttonNowTranslation="Agora"
      locale="pt-BR"
      @focus="handleFocus"
      @blur="handleBlur"
    />

    <span :class="{ disabled: isDisabled, error: errors[0] }">
      {{ required ? "*" : "" }}
    </span>

    <div v-if="!isDisabled" class="errors">
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import { ValidationProvider } from "vee-validate";
import moment from "moment";

export default {
  name: "DateTimePicker",

  components: {
    ValidationProvider,
    VueCtkDateTimePicker,
  },

  props: {
    vid: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: "Placeholder",
    },

    rules: {
      type: [Object, String],
      default: "",
    },

    value: {
      type: [String, Number, Object],
      default: "",
    },

    maxLength: {
      type: Number,
      required: false,
    },

    name: {
      type: String,
      required: false,
      default: "name",
    },

    hasFocus: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    format: {
      type: String,
      default: "YYYY-MM-DD HH:mm",
    },

    formatted: {
      type: String,
      default: "llll",
    },

    noButtonNow: {
      type: Boolean,
      default: false,
    },

    dateOnly: {
      type: Boolean,
      default: false,
    },

    minDate: {
      type: Object,
      default: () => moment().subtract(100, "years"),
    },

    maxDate: {
      type: Object,
      default: () => moment().add(100, "years"),
    },

    timeOnly: {
      type: Boolean,
      default: false,
    },

    minuteInterval: {
      type: Number,
      default: 1,
    },

    right: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    minDateFormatted() {
      return moment(this.minDate).format(this.format);
    },
    maxDateFormatted() {
      return moment(this.maxDate).format(this.format);
    },
  },

  data() {
    return {
      innerValue: "",
      visibility: false,
      inputType: "text",
      focusStatus: false,
      content: this.value,
      inputName: "",
      hasSize: null,
      fixForLowRes: null,
      config: {
        wrap: true,
        enableTime: true,
        dateFormat: this.format ? this.format : "YYYY-MM-DD HH:mm",
        minDate: new Date(),
      },
    };
  },

  watch: {
    innerValue(value) {
      if (this.timeOnly && typeof value === "string") {
        if (window.innerWidth < 417) {
          if (value.slice(0, 2) != "00") {
            this.fixForLowRes = value;
          }

          if (this.fixForLowRes != this.innerValue) {
            this.innerValue = this.fixForLowRes;
          }
        }
      }

      this.$emit("input", this.innerValue);
    },

    value(value) {
      if (value !== this.innerValue) {
        this.innerValue = value;
      }
    },
  },

  created() {
    this.inputType = this.type;

    if (this.value) {
      this.innerValue = this.value;
    }
  },

  mounted() {
    if (this.name) this.inputName = this.name;
    else this.inputName = this.placeholder.toLowerCase();
  },

  methods: {
    handleFocus() {
      this.focusStatus = true;
    },

    handleBlur() {
      this.focusStatus = false;
      this.$emit("blur", true);
    },

    isFocus() {
      return this.focusStatus;
    },

    isPassword() {
      return this.type === "password";
    },

    toggleVisibility() {
      if (this.visibility) {
        this.visibility = false;
      } else {
        this.visibility = true;
      }
    },
  },
};
</script>

<style scoped>
.ctk-date-time-picker {
  margin-bottom: 13px;
}
</style>
