<template>
  <div class="media-container">
    <div
      v-if="opportunity.old_chats"
      :style="{ 'padding-bottom': opportunity.chat_history ? '0px' : '62px' }"
      class="w-100"
    >
      <div v-for="(chat, index) in opportunity.old_chats" :key="index">
        <div class="line-and-chat-start">
          <div class="start-message">
            <!-- getBotName(opportunity.remote_id) + -->
            {{
              (typeof opportunity.remote_id === "string" ? "" : "Sala: #" + opportunity.remote_id) +
              " - " +
              getFormatedDate(chat[0].timestamp)
            }}
          </div>
        </div>

        <chat-message-component v-for="(message, index) in chat" :key="index" :message="message" />
      </div>
    </div>

    <div v-if="opportunity.chat_history" class="w-100" style="padding-bottom: 62px">
      <div class="line-and-chat-start">
        <div class="start-message">
          <!-- getBotName(opportunity.remote_id) + -->
          {{
            (typeof opportunity.remote_id === "string" ? "" : "Sala: #" + opportunity.remote_id) +
            " - " +
            getFormatedDate(opportunity.chat_history[0].timestamp)
          }}
        </div>
      </div>

      <chat-message-component
        v-for="(message, index) in opportunity.chat_history"
        :key="index"
        :message="message"
      />
    </div>

    <div
      v-if="!opportunity.old_chats && !opportunity.chat_history"
      class="vh-100 d-flex flex-column"
      style="align-items: center"
    >
      <h3 class="text-center pt-2 px-2 pb-2">Nenhuma conversa para está oportunidade</h3>

      <lottie-animation
        class="no-chat-animation"
        ref="anim"
        :animationData="require('@/assets/images/lottie/chat-lottie.json')"
        :loop="true"
        :autoPlay="true"
        :speed="1"
      />
    </div>
  </div>
</template>

<script>
import { BTab, BTabs, BButton } from "bootstrap-vue";
import ChatMessage from "@/views/components/chat/ChatMessage.vue";
import LottieAnimation from "lottie-web-vue";
import moment from "moment";

export default {
  components: {
    BTab,
    BTabs,
    BButton,
    LottieAnimation,
    "chat-message-component": ChatMessage,
  },

  props: {
    opportunity: {
      type: Object,
      default: null,
      required: true,
    },
  },

  data() {
    return {
      chatMessages: [],
      hasRequest: false,
      messagesLength: 0,
    };
  },

  watch: {
    opportunity() {
      if (this.opportunity.chat_history) {
        const currentLength = this.opportunity.chat_history.length;

        if (currentLength > this.messagesLength) {
          this.setAllImages();
        }

        this.messagesLength = this.opportunity.chat_history.length;
      }
    },
  },

  mounted() {
    this.setAllImages();
  },

  methods: {
    OpenRocketChatChannel(remoteId) {
      window.open(`https://chat.institutomix.com.br/omnichannel/current/${remoteId}/room-info`);
    },

    getBotName(id) {
      return this.opportunity.chat_history[1].name;
    },

    setAllImages() {
      if (!this.opportunity.chat_history) {
        return;
      }

      const chatMessages = this.opportunity.chat_history;
      const possibleImageTypes = {
        "image/jpeg": "image",
        "image/png": "image",
      };

      let allImages = [];

      chatMessages.forEach((message) => {
        if (message.files) {
          message.files.forEach((element) => {
            if (possibleImageTypes[element.mime]) {
              allImages.push(element.url);
            }
          });
        }
      });

      this.$store.dispatch("modalCarousel/setCarouselImages", allImages);
    },

    getFormatedDate(date) {
      const parsedDate = moment.parseZone(date);

      return parsedDate.format("DD/MM/YYYY HH:mm:ss");
    },
  },
};
</script>

<style lang="scss" scoped>
.media-container {
  height: auto;
  width: 100%;

  .line-and-chat-start {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1px;
    width: 100%;
    margin: 15px 0;
    background: #e5e5e5;

    .start-message {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      padding: 1px 15px;
      border-radius: 800px;
      background: #c4e2ff;
      color: #192a3e;
    }
  }

  .no-chat-animation {
    max-width: 400px;
  }
}
</style>
