/* eslint-disable no-empty-pattern */
import { instance } from "@/config/index.js";

const initialState = () => ({});

const getters = {};

const mutations = {};

const actions = {
  exportContacts({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const url = `/workspaces/${workspaceId}/exports/contacts`;

    return new Promise((resolve, reject) => {
      instance()
        .get(url, data)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  exportPDCA({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const url = `/workspaces/${workspaceId}/exports/pdca?${params || ""}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(url)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  generateDownloadLink({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const url = `/workspaces/${workspaceId}/exports/${id}/download`;

    return new Promise((resolve, reject) => {
      instance()
        .get(url, { responseType: "arraybuffer" })
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
