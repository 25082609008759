<template>
    <!-- basic modal -->
    <b-modal
      id="modal-default"
      :title="title"
      hide-footer
      hide-header
      :visible="visible"
    >
      <b-form
        class="p-2"
        @submit.prevent
      >
        <b-card-text>
          <slot></slot>

          <!-- Form Actions -->
          <div class="action-bar modal-bottom">
            <b-button variant="primary" class="mr-2" type="submit" @click="Cancel">
              Cancelar
            </b-button>
            <b-button variant="primary" class="mr-2 btn-save" type="submit" @click="Save">
              Salvar
            </b-button>
          </div>
        </b-card-text>
      </b-form>
    </b-modal>

</template>

<script>
import {
  BModal, VBModal, BCardText, BButton, BForm
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import "vue-select/dist/vue-select.css";

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BForm
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    title : {
      type: String,
      default: null
    }, 
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    Save() {
      this.$emit('saved');
    },
    Cancel() {
      this.$emit('closed');
    }

  },

};
</script>
