<template>
  <div @click="getAudiosReady()" id="app" class="h-100" :class="[skinClasses]">
    <loading id="loading-screen" :data="loadingData" />

    <news-warning v-if="getNewsState" :warning="warning" />

    <dial-bar />

    <modal-faq-videos v-if="getFaqState" />

    <modal-carousel v-if="modalCarouselState" />

    <b-modal-central />

    <norification-toast-holder />

    <router-view />
  </div>
</template>

<script>
import BModalCentral from "./views/components/BModalCentral.vue";
import DialBar from "./views/components/External Phone/DialBar.vue";
import { onMessage, getToken } from "firebase/messaging";
import Loading from "../src/views/components/Loading.vue";
import ModalCarousel from "./views/components/ModalCarousel";
import ModalFaqVideos from "./views/components/Tutorials/ModalFaqVideos.vue";
import moment from "moment";
import NewsWarning from "./views/components/NewsWarning.vue";
import store from "@/store";
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import useAppConfig from "@core/app-config/useAppConfig";
import Vue from "vue";
import VueCookie from "vue-cookie";
import { useCssVar } from "@vueuse/core";
import NorificationToastHolder from "@/views/components/NotificationToastHolder.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

Vue.use(VueCookie);

export default {
  components: {
    BModalCentral,
    DialBar,
    Loading,
    ModalCarousel,
    ModalFaqVideos,
    NewsWarning,
    NorificationToastHolder,
  },

  data() {
    return {
      skinClasses: [],
      warning: null,

      fbToken: null,
      broadcastChannel: null,
    };
  },

  computed: {
    loadingData() {
      this.$store.dispatch("whiteLabel/setPlatform");
      const data = this.$store.getters["whiteLabel/getPlatform"];

      return data;
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    getFaqState() {
      return this.$store.state.faqVideos.isOpen;
    },

    getNewsState() {
      return this.$store.state.newsWarning.isOpen;
    },

    allWarnings() {
      return this.$store.getters["newsWarning/getAllWarnings"];
    },

    updateTick() {
      return this.$store.state.newsWarning.updateTick;
    },

    modalCarouselState() {
      return this.$store.getters["modalCarousel/getCarouselState"];
    },

    isReadyAudio() {
      return this.$store.getters["externalPhone/getIsReadyAudios"];
    },

    updateFirebaseTokenVar() {
      return this.$store.getters["firebase/getUpdateToken"];
    },

    isShowingNotification() {
      return this.$store.getters["firebase/getIsShowingNotification"];
    },
  },

  beforeCreate() {
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2)
      );
    }

    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },

  created() {
    this.cleanStates();

    this.initialSetup();

    this.checkPermissionForFB();

    this.addMessageListener();
  },

  mounted() {
    this.addGtag();
    this.addClarity();
  },

  beforeDestroy() {
    this.broadcastChannel.close();
  },

  watch: {
    updateTick() {
      this.checkNews();
    },

    updateFirebaseTokenVar() {
      this.checkPermissionForFB();
    },
  },

  methods: {
    setNotificationOnStore(notification) {
      this.$store.commit("firebase/setHasNotificationBullet", true);

      if (this.isShowingNotification) return;

      this.$store.commit("firebase/setCurrentNotification", notification);
    },

    cleanStates() {
      this.$store.getters["opportunityStore/resetState"];
      this.$store.commit("externalPhone/RESET_STATE");
      this.$store.dispatch("modalCarousel/changeShowCarousel", false);
    },

    addMessageListener() {
      this.broadcastChannel = new BroadcastChannel("sw-messages");

      this.broadcastChannel.onmessage = (event) => {
        this.setNotificationOnStore(event.data);
      };
    },

    getAudiosReady() {
      if (this.isReadyAudio) {
        return;
      }

      this.$store.commit("externalPhone/TRIGGER_USER_INTERACTION");
    },

    initialSetup() {
      const { skin, skinClasses } = useAppConfig();

      if (skin.value === "dark") document.body.classList.add("dark-layout");

      store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);

      this.skinClasses = skinClasses;
    },

    checkNews() {
      const currentPath = window.location.pathname;

      if (this.allWarnings && currentPath != "/login") {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 10);

        let warning;

        this.allWarnings.forEach((element) => {
          const currentDate = moment();
          const warningExpiration = moment(element.expiration, "DD/MM/YYYY");
          const hasPassed = warningExpiration.isSameOrBefore(currentDate);

          warning = localStorage.getItem(element.id);
          this.warning = element;

          if (!warning) {
            localStorage.setItem(element.id, true);

            if (!hasPassed) {
              this.$store.commit("newsWarning/CHANGE_NEWS_MODAL_CONDITION", true);
            }

            return;
          }
        });
      }
    },

    addGtag() {
      let script = document.createElement("script");

      script.textContent = `
        try {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "G-5E21DWH9DR");
        } catch (error) {
          console.error('Erro ao executar o Google Tag Manager:', error);
        }
      `;

      document.head.appendChild(script);
    },

    addClarity() {
      try {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = `
          (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () {
              (c[a].q = c[a].q || []).push(arguments);
            };
            t = l.createElement(r);
            t.async = 1;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
          })(window, document, "clarity", "script", "ldhn5jv14v");
        `;
        document.head.appendChild(script);
      } catch (error) {
        console.error("An error occurred while inserting the script: ", error);
      }
    },

    getDeviceType() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "Ios";
      }

      return "Desktop";
    },

    async checkPermissionForFB() {
      try {
        if (!this.user?.id) {
          return;
        }

        this.hasSupport = window?.Notification;

        if (
          !this.hasSupport ||
          !firebase.messaging.isSupported() ||
          this.getDeviceType() == "Ios"
        ) {
          return;
        }

        const permission = await Notification.requestPermission();

        if (permission === "granted") {
          this.isPermissionGranted = true;

          if ("serviceWorker" in navigator && navigator.serviceWorker.ready) {
            this.setFBToken();
          } else {
            console.error("Service Worker is not ready");
          }
        }
      } catch (error) {
        console.error("Erro ao verificar permissão para FB: ", error);
      }
    },

    async setFBToken() {
      const fbToken = await getToken(this.$messaging, {
        vapidKey:
          "BD8rZjzUHP9JK2F_cXn-yqbVFRbnLqTvf72dDE5kMOi-6K_8fGEf6HpJoimoKWjgKrjQgzAzCmpHZeWBw_AmOFs",
      });

      this.fbToken = fbToken;

      this.sendFBToken();
    },

    async sendFBToken() {
      if (!this.fbToken) {
        return;
      }

      const tokenData = {
        token: this.fbToken,
        device_type: this.getDeviceType(),
      };

      return this.$store
        .dispatch("firebase/registerToken", tokenData)
        .then(async () => {
          const messaging = await this.$messaging;

          onMessage(messaging, (payload) => {
            this.setNotificationOnStore(payload);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
