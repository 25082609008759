<template>
  <button :disabled="disabled" :class="disabled ? 'disabled' : `${styleType}`">
    <b-spinner v-if="spinner" />

    <div v-else class="button-content">
      <div v-if="!!iconLeft" class="icon-left-container">
        <i :class="iconLeft" />
      </div>

      {{ text }}

      <div v-if="!!iconRight" class="icon-right-container">
        <i :class="iconRight" />
      </div>
    </div>
  </button>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BSpinner,
  },

  props: {
    text: {
      type: String,
      default: "Button",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    spinner: {
      type: Boolean,
      default: false,
    },

    iconLeft: {
      type: String,
      required: false,
    },

    iconRight: {
      type: String,
      required: false,
    },

    styleType: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/components/button-component.scss";
</style>
