/* eslint-disable no-empty-pattern */
import moment from "moment";
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  showByYear: true,
  selectedMonth: { value: "01", text: "Janeiro" },
  selectedYear: 2023,
  years: [2023],
  mediasFromFaseTwo: [],

  redirect_date: null,

  filters: {
    start_timestamp: null,
  },

  monthsOfTheYear: [
    { text: "Janeiro", value: "1" },
    { text: "Fevereiro", value: "2" },
    { text: "Março", value: "3" },
    { text: "Abril", value: "4" },
    { text: "Maio", value: "5" },
    { text: "Junho", value: "6" },
    { text: "Julho", value: "7" },
    { text: "Agosto", value: "8" },
    { text: "Setembro", value: "9" },
    { text: "Outubro", value: "10" },
    { text: "Novembro", value: "11" },
    { text: "Dezembro", value: "12" },
  ],

  planning_status: {
    1: "PrePlanning",
    2: "Planning",
    3: "PlanningMedias",
    4: "Planning5w2h",
    5: "Submitted",
  },
});

const getters = {
  getMonths(state) {
    return state.monthsOfTheYear;
  },

  getYears(state) {
    return state.years;
  },

  getMideasFromFaseTwo(state) {
    return state.mediasFromFaseTwo;
  },

  getFilters(state) {
    return state.filters;
  },

  getRedirectDate(state) {
    return state.redirect_date;
  },
};

const actions = {
  setMediasFromFaseTwo({ state }, medias) {
    state.mediasFromFaseTwo = medias;
  },

  setRedirectDate({ state }, date) {
    state.redirect_date = date;
  },

  cleanRedirectDate({ state }) {
    state.redirect_date = null;
  },

  resetState({ state }) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },

  cleanValues({ state }) {
    state.filters = {
      start_timestamp: null,
    };

    return state.filters;
  },

  checkYears({ state }) {
    const currentYearPlusOne = moment().add(1, "year").year();
    const baseYear = 2023;

    state.years = [];
    let year = currentYearPlusOne;

    do {
      state.years.push(year);
      year = year - 1;
    } while (year >= baseYear);
  },

  fetchMedias({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/medias${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchPlanning({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/plannings/${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchPlanningMedias({}, planningId) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/plannings/${planningId}/medias`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchPlanningMediaConsult({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/plannings/${data.planningId}/medias/${data.mediaId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  saveFaseOne({}, planning) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/plannings`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, planning)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  savePlanningMedias({}, { media, planningId }) {
    const postUrl = `/plannings/${planningId}/medias`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, media)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updatePlanning({}, planning) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/plannings/${planning.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, planning)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updatePlanningMedia({}, { media, planningId }) {
    const putUrl = `/plannings/${planningId}/medias/${media.media_id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, media)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updatePlanningFase({}, { fase, planningId }) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const patchUrl = `/workspaces/${workspaceId}/plannings/${planningId}`;

    return new Promise((resolve, reject) => {
      instance()
        .patch(patchUrl, fase)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteMedia({}, { mediaId, planningId }) {
    const deleteUrl = `/plannings/${planningId}/medias/${mediaId}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //Templates

  fetchTemplates({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/schedule-templates${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchAvailableRoutineTemplates({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/schedule-templates/list${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchOneTemplate({}, templateId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/schedule-templates/${templateId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  updateTemplate({}, template) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/schedule-templates/${template.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, template)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  saveTemplate({}, template) {
    const postUrl = `/workspaces/${template.workspace.id}/schedule-templates`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, template)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteTemplate({}, templateId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/schedule-templates/${templateId}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //Template Schedules

  fetchTemplatesSchedule({}, attributes) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/schedule-templates/${attributes.templateId}/tasks${attributes.params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchOneTemplateSchedule({}, template) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/schedule-templates/${template.id}/tasks/${template.taskId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  updateTemplateSchedule({}, template) {
    const post = `/schedule-templates/${template.id}/tasks/${template.taskId}`;

    return new Promise((resolve, reject) => {
      instance()
        .post(post, { task: template.tasks })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateTemplateScheduleMass({}, template) {
    const postUrl = `/schedule-templates/${template.id}/tasks-batch-upsert`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, { task: template.tasks })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  saveTemplateSchedule({}, template) {
    const postUrl = `/schedule-templates/${template.id}/tasks`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, { task: template.tasks })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteTemplateSchedule({}, template) {
    const deleteUrl = `/schedule-templates/${template.id}/tasks/${template.taskId}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  // Planning Schedule (Tasks)

  fetchPlanningTasks({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/schedule-tasks${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  savePlanningTasks({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/schedule-tasks`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchPlanningTask({}, taskId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/schedule-tasks/${taskId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  updatePlanningTask({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/schedule-tasks/${task.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  closePlanningTask({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const patchUrl = `/workspaces/${workspaceId}/schedule-tasks/${task.id}/end`;

    return new Promise((resolve, reject) => {
      instance()
        .patch(patchUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  reschedulePlanningTask({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const patchUrl = `/workspaces/${workspaceId}/schedule-tasks/${task.id}/reschedule`;

    return new Promise((resolve, reject) => {
      instance()
        .patch(patchUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deletePlanningTask({}, taskId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/schedule-tasks/${taskId}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //Task imports

  fetchImportedTasks({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/schedule-templates-tasks${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  saveImportedTasks({}, tasks) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/schedule-templates-tasks`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, { task: tasks })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchSpecificMedias({}, role) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${workspaceId}/medias?limit=500&schedule_template_type=Action&role=${role}`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchSpecificCampaigns({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${workspaceId}/campaigns?limit=500&schedule_template_type=Action&schedule_template_task_media_id=${params.mediaId}&role=${params.role}`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  //PDCA

  getPdcaSummary({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/summary${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getPdcaLeads({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/opportunities${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getPdcaEnrollments({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/enrollments${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getRemotePdcaEnrollments({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/remote-enrollments${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getPdcaSales({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/sales${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getRemotePdcaSales({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/remote-sales${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getPdcaTeachingMaterials({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/courseware${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getRemotePdcaTeachingMaterials({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/remote-courseware${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getPdcaInvoicing({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/income${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getRemotePdcaInvoicing({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/remote-income${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getPdcaSubmitted({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/planning-dashboards/submitted${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
};

const mutations = {
  CHANGE_SHOW_TYPE(state, value) {
    state.showByYear = value;
  },

  CHANGE_SELECTED_MONTH(state, value) {
    state.selectedMonth = value;
    state.filters.start_timestamp = state.selectedYear + "," + state.selectedMonth.value;
  },

  CHANGE_SELECTED_YEAR(state, value) {
    state.selectedYear = value;
    state.filters.start_timestamp = state.selectedYear + "," + state.selectedMonth.value;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
