<template>
  <div id="transferWorkspace" v-if="opportunity">
    <div class="content">
      <div class="header">
        <i class="icon-close" @click="cancel" />
      </div>

      <span @click="openModal('bv-workspace-list')" class="cursor-pointer">
        <workspace-item label="Workspace" variant="input" v-model="selectedWorkspace" />
      </span>

      <div v-if="isItMissingStageError" class="errorText">
        <span class="text-danger">Você deve escolher uma etapa! </span>
      </div>

      <div v-if="selectedWorkspace" class="select-distribution">
        <v-select
          v-if="!hasStageRequest"
          :options="salesStages"
          :reduce="(option) => option.id"
          :getOptionLabel="(option) => option.name"
          label="stage"
          placeholder="Selecione a etapa:"
          v-model="selectedStage"
        />

        <b-spinner v-else label="Spinning" />
      </div>

      <div class="select-distribution">
        <v-select
          v-model="selectedDistribution"
          :options="distributionTypes"
          label="title"
          :reduce="(option) => option.id"
          :clearable="false"
        />
      </div>

      <div class="errorText" v-if="isItMissingUserIDError">
        <span class="text-danger">Você deve escolher algum responsável! </span>
      </div>

      <div class="sequential-info" v-if="selectedDistribution == 'WorkspaceConfiguration'">
        <feather-icon icon="AlertCircleIcon" size="22" class="icon" />

        <span
          >A oportunidade vai ser transferida conforme a configuração de distribuição do workspace
          selecionado.
        </span>
      </div>

      <span
        @click="openModal('bv-user-list')"
        class="cursor-pointer"
        v-if="selectedDistribution == 'SpecificUser'"
      >
        <user-item label="Responsável" variant="input" v-model="selectedUser" />
      </span>

      <div class="keep-users-check">
        <input type="checkbox" class="checkbox" v-model="keepUsers" />

        <div>Manter responsável existente</div>

        <i id="keep-user-info" class="icon-information-circle" />

        <b-tooltip target="keep-user-info" triggers="hover" placement="top">
          {{ keepUserInfo }}
        </b-tooltip>
      </div>

      <h3 class="text-center text-dark mt-3">Transferir para outra área de trabalho</h3>

      <div class="generalImage d-flex justify-center align-center mt-4">
        <img src="@/assets/images/transfer-image.svg" class="w-100" />
      </div>
    </div>

    <b-modal class="modal-dialog" id="bv-workspace-list" hide-footer hide-header>
      <workspace-list @selectedWorkspace="changeSelectedWorkspace($event)" />
    </b-modal>

    <b-modal class="modal-dialog" id="bv-user-list" hide-footer hide-header>
      <user-picker-from-another-workspace
        :showManagers="true"
        :workspaceId="selectedWorkspace.id"
        @selectedUser="setSelectedUser($event)"
      />
    </b-modal>

    <div class="action-buttons">
      <h5 @click="cancel">Cancelar</h5>

      <h5 @click="transferOpportunity" v-if="!hasRequest">Transferir</h5>

      <b-spinner v-else />
    </div>
  </div>
</template>

<script>
import WorkspaceItem from "./WorkspaceItem.vue";
import WorkspaceList from "./WorkspaceList.vue";
import UserItem from "./UserItem.vue";
import { BSpinner, BTooltip } from "bootstrap-vue";
import UserPickerFromAnotherWorkspace from "./UserPickerFromAnotherWorkspace.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { errorHandling } from "@/mixins";
import { instance } from "@/config/index.js";

export default {
  components: {
    WorkspaceItem,
    WorkspaceList,
    UserItem,
    UserPickerFromAnotherWorkspace,
    vSelect,
    BSpinner,
    BTooltip,
  },

  props: {
    opportunity: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      selectedWorkspace: null,
      selectedUser: null,
      selectedStage: null,
      keepUsers: false,

      hasRequest: false,
      hasStageRequest: false,

      isItMissingUserIDError: false,
      isItMissingStageError: false,

      selectedDistribution: "WorkspaceConfiguration",

      keepUserInfo:
        "Ao selecionar esta opção, a oportunidade será transferida para o mesmo responsável. Se este não estiver disponível, a transferência seguirá conforme a configuração, seja ela Sequencial ou para um usuário específico.",

      salesStages: [],

      distributionTypes: [
        { id: "WorkspaceConfiguration", title: "Configuração do workspace" },
        { id: "SpecificUser", title: "Colaborador específico" },
      ],
    };
  },

  mixins: [errorHandling],

  created() {
    this.changeSelectedWorkspace(this.opportunity.workspace);
  },

  mounted() {
    this.openModal("bv-workspace-list");
  },

  watch: {
    selectedWorkspace(newSelected, oldSelected) {
      if (newSelected != oldSelected) {
        this.selectedStage = null;

        this.fetchSalesStages();
      }
    },
  },

  methods: {
    hideModal(modal) {
      this.$bvModal.hide(`${modal}`);
    },

    cancel() {
      this.hideModal("bv-tranfer-opportunity");
      this.$emit("closed");
    },

    showWarning(title, message, type) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 1500,
        variant: type,
        toaster: "b-toaster-top-left",
        solid: true,
      });
    },

    salesStageRequest() {
      const workspaceId = this.selectedWorkspace.id;

      const postUrl = `/workspaces/${workspaceId}/sales-stages?limit=500`;

      return new Promise((resolve, reject) => {
        instance()
          .get(postUrl)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchSalesStages() {
      this.hasStageRequest = true;

      this.salesStageRequest()
        .then((response) => {
          this.salesStages = response.data;

          this.salesStages = this.salesStages.filter(
            (stage) => stage.type != "Won" && stage.type != "Lost"
          );
        })
        .catch((error) => {
          this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
        })
        .finally(() => {
          this.hasStageRequest = false;
        });
    },

    transferOpportunity() {
      this.hasRequest = true;
      this.isItMissingUserIDError = false;
      this.isItMissingStageError = false;

      if (!this.selectedStage) {
        this.hasRequest = false;
        this.isItMissingStageError = true;
        return;
      }

      if (this.selectedDistribution == "SpecificUser" && !this.selectedUser) {
        this.hasRequest = false;
        this.isItMissingUserIDError = true;
        return;
      }

      const dataForTransfer = {
        workspace_id: this.selectedWorkspace.id,
        user_id: null,
        opportunity_id: this.opportunity.id,
        sales_stage_id: this.selectedStage,
        keep_user_id: this.keepUsers,
      };

      if (this.selectedUser) {
        dataForTransfer.user_id = this.selectedUser.id;
      }

      if (this.selectedDistribution == "WorkspaceConfiguration" && this.selectedUser) {
        dataForTransfer.user_id = null;
      }

      this.$store
        .dispatch("opportunityStore/transferOpportunity", dataForTransfer)
        .then(() => {
          this.$emit("transfered");
          this.$bvModal.hide("bv-tranfer-opportunity");
        })
        .catch((error) => {
          if ((error.response.message = "This Opportunity is already closed.")) {
            this.showWarning(
              "Oportunidade Fechada",
              "Oportunidades fechadas não podem ser transferidas",
              "danger"
            );
          }

          this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
          this.hasRequest = false;
        })
        .finally(() => {
          this.hasRequest = false;
        });
    },

    openModal(modal) {
      this.$bvModal.show(`${modal}`);
    },

    changeSelectedWorkspace(workspace) {
      this.selectedWorkspace = workspace;
      this.selectedUser = null;
    },

    setSelectedUser(user) {
      this.selectedUser = user;
    },
  },
};
</script>

<style lang="scss" scoped>
#transferWorkspace {
  .content {
    padding: 20px 20px 0px 20px;

    .header {
      margin-bottom: 40px;
      .icon-close {
        cursor: pointer;
      }
    }

    .errorText {
      font-size: 12px;
      text-align: center;
    }

    .select-distribution {
      margin: 15px 0;
      width: 100%;
    }

    .sequential-info {
      height: auto;
      width: 100%;
      background: #e1edfe;
      border: 1px solid #046af34d;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 0.2rem;
      .icon {
        color: #046cf3;
        width: 30px;
        height: 30px;
        margin-left: 10px;
      }
      span {
        color: #000;
        text-align: center;
      }
    }

    .keep-users-check {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 10px;
      gap: 5px;

      .checkbox {
        height: 20px;
        width: 20px;
      }

      i {
        font-size: 20px;
        color: #046af3;
      }
    }
  }

  .action-buttons {
    display: flex;
    position: fixed;
    max-width: 500px;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #fff;
    border-top: 1px solid #e4e4e4;
    justify-content: space-around;
    align-items: center;
    :nth-child(1) {
      color: black;
      cursor: pointer;
      padding: 8px 5px;
      font-weight: bold;
    }
    :nth-child(2) {
      cursor: pointer;
      font-weight: bold;
      color: #046af3;
      padding: 8px 5px;
    }
  }
}
</style>
