<template>
  <div id="list-opportunity" class="d-flex flex-column h-100">
    <div class="d-flex justify-content-between pt-2 header">
      <h3 class="mt-1 mx-auto">Selecionar Oportunidade</h3>
    </div>

    <div class="d-flex mt-1 px-2">
      <ul>
        <li class="search">
          <input-field
            v-model="search"
            placeholder="Buscar"
            @keyup="queueSearch"
          />

          <i class="icon-search" />

          <i class="icon-close" @click="cleanSearch" />
        </li>

        <li
          class="d-flex align-items-center justify-content-center mt-1 loading"
          v-if="hasRequest"
        >
          <b-spinner />
        </li>

        <li v-for="item in items" :key="item.id" @click="select(item.id)">
          <opportunity-item
            :opportunity="item"
            :fromTaskSelect="fromTaskModal"
          />
        </li>
      </ul>
    </div>

    <div class="action-buttons">
      <b-button class="button-1" variant="light" @click="cancel()">
        Cancelar
      </b-button>

      <b-button
        class="button-2"
        variant="primary"
        @click="createNewOpportunity()"
      >
        Criar Novo
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from "bootstrap-vue";
import InputField from "./InputField.vue";
import opportunityItem from "./OpportunityItem.vue";

export default {
  components: {
    InputField,
    BButton,
    BSpinner,
    opportunityItem,
  },

  data() {
    return {
      search: "",
      hasRequest: false,
      items: [],
      queueingSearch: 0,
    };
  },

  props: {
    fromTaskModal: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.fetch(true);
  },

  computed: {
    workspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    opportunityCreationEvent() {
      return this.$store.getters[
        "bmodalEvents/getOpportunityCreationInsideOpportunitySelectEvent"
      ];
    },
  },

  watch: {
    opportunityCreationEvent(oldValue, newValue) {
      if (oldValue != newValue) {
        this.fetch(true);

        this.hideModal("bv-create_opportunity_modal_inside_task");
      }
    },
  },

  methods: {
    hideModal(modal) {
      this.$bvModal.hide(modal);
    },

    createNewOpportunity() {
      this.$bvModal.show("bv-create_opportunity_modal_inside_task");
    },

    queueSearch() {
      window.clearTimeout(this.queueingSearch);
      this.queueingSearch = setTimeout(() => {
        this.fetch();
      }, 1000);
    },

    async fetch(ignoreLength = false) {
      let opportunities = [];

      let search = [];
      if (this.search.length == 0 || !ignoreLength) {
        this.items = [];
      }

      if (this.search.length > 2 || ignoreLength) {
        search = [
          {
            query: "search",
            param: this.search,
          },
        ];

        const params = [
          {
            query: "limit",
            param: 15,
          },
          {
            query: "sort",
            param: "-created_at",
          },
        ];

        let userParam = [];
        let role = this.workspace.roles || false;
        if (role && role === "salesRep") {
          userParam = [
            {
              query: "user_id",
              param: this.user.id,
            },
          ];
        }

        const that = this;
        that.hasRequest = true;
        await this.$store
          .dispatch(
            "opportunityStore/fetch",
            this.$service.formatQueryParam([...params, ...search, ...userParam])
          )
          .then((p) => {
            that.hasRequest = false;
            opportunities = p.data;
          });
        this.items = opportunities;
      }
    },

    cancel() {
      this.$emit("closed");
    },

    select(selectedId) {
      const selectedItem = this.items.filter(
        (item) => item.id === selectedId
      )[0];
      this.$emit("selected", selectedItem);
    },

    cleanSearch() {
      this.search = "";
      this.fetch(true);
    },
  },
};
</script>

<style lang="scss" scoped>
#list-opportunity {
  ul {
    border: 1px solid #e5e5e5;
    border-bottom: none;
    padding: 0;
    margin: 0;

    li.search {
      padding: 0px;
      margin: 0px;
      width: 455px;
      max-width: 90vw;

      .input-field-container {
        padding: 0px;
        margin: 0px;
        :deep(input) {
          border: none;
        }
      }
      .icon-search {
        position: absolute;
        right: 40px;
        line-height: 45px;
      }
      .icon-close {
        position: absolute;
        right: 65px;
        line-height: 45px;
        font-size: 0.8em;
      }
    }
    li {
      list-style: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 9px;
      display: flex;
      flex-direction: column;

      &:hover {
        font-weight: 500;
        box-shadow: 1px 1px 4px 1px #a5a5a53d;
      }
    }
  }

  .action-buttons {
    background: #fff;
    border-top: 1px solid #d2d2d2;
    height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 50;
    max-width: 500px;

    .button-1 {
      color: black;
      padding: 10px;
      font-weight: bold;
      cursor: pointer;
    }
    .button-2 {
      color: #046af3;
      padding: 10px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
</style>
