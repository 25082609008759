<template>
  <div class="contact-selector">
    <div class="holder">
      <div class="d-flex flex-row text-left contactBox">
        <avatar
          avatarBackground="#109CF1"
          avatarColor="#FFFFFF"
          :avatarLetter="$service.getFirstLetter(contact.name || '')"
          :hasBorder="false"
          :hasStatus="false"
          :size="5"
          style="margin-right: 10px"
        />
        <div class="d-flex flex-column">
          <span class="name"> {{ contact.name }} </span>
          <span class="number" v-if="contact.phone_number">
            {{ formatNumber(contact.phone_number) }}

            <div v-if="cardId !== 0" class="lowres-card-id">
              <span>{{ `#${cardId}` }}</span>
            </div>
          </span>

          <div
            v-if="midiaType"
            style="
              display: flex;
              flex-direction: row;
              justfy-content: center;
              align-items: center;
            "
          >
            <feather-icon icon="Share2Icon" size="16" />
            <span style="margin-left: 5px">{{ midiaType }}</span>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              justfy-content: center;
              align-items: center;
            "
          >
            <i
              v-if="workspaceName"
              style="font-size: 15px"
              class="icon-icon-notebook"
            />
            <span style="margin-left: 5px">{{ workspaceName }}</span>
          </div>
        </div>

        <div
          v-if="cardId !== 0"
          :class="isFromOmnichannelDesktop ? 'cardIdOmnichannel' : 'cardId'"
        >
          <span>{{ `#${cardId}` }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";

export default {
  components: {
    Avatar,
  },
  props: {
    contact: {
      type: Object,
      default() {
        return {};
      },
    },

    cardId: {
      type: Number,
      default: 0,
    },

    workspaceName: {
      type: String,
      default: "",
    },

    midiaType: {
      type: String,
      default: "",
    },

    isFromOmnichannelDesktop: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},

  methods: {
    formatNumber(number) {
      number = number
        .toString()
        .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");

      return number;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 0.25em;
  .holder {
    display: flex;
    flex: 1;
    padding: 0.25em;
    border-radius: 6px;
    flex-direction: row;
    justify-content: space-between;

    .contactBox {
      display: flex;
      align-items: center;
      position: relative;

      .name {
        font-weight: 500;
        font-size: 16px;
        color: #171717;
      }
    }
  }

  .cardId,
  .cardIdOmnichannel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 30px;
    width: 50px;
    left: 375px;
    margin-top: 7px;
    padding: 1rem 2.5rem;
    border-radius: 10px;
    border: 1px solid #e0e0e0;

    span {
      font-size: 12px;
      color: #046af3;
    }
  }

  @media (max-width: 1500px) {
    .cardIdOmnichannel {
      left: 300px;
    }
  }

  .lowres-card-id {
    display: none;
  }

  @media (max-width: 500px) {
    .cardId,
    .cardIdOmnichannel {
      display: none;
    }

    .number {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .lowres-card-id {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 50px;
      margin-left: 5px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;

      span {
        color: #046af3;
        font-size: 12px;
      }
    }
  }
}
</style>
