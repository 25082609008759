<template>
  <b-card id="opportunityRecreated">
    <div class="header">
      <span class="title">{{ "Sala #" + log.data.remote_id + " - Fechada " }} </span>
    </div>

    <div class="footer d-flex flex-column">
      <div class="info" v-if="!fromUserModal && log.user">
        Realizado por:

        <div class="d-flex flex-row">
          <Avatar
            :hasStatus="false"
            :avatarLetter="$service.getFirstLetter(log.user.name || 'X')"
            avatarColor="#FFFFFF"
            avatarBackground="#109CF1"
          />

          <div class="d-flex flex-column">
            <span class="name"> {{ log.user.name }} </span>

            <span class="cod"> #{{ log.user.id }} </span>
          </div>
        </div>
      </div>

      <div
        v-if="(fromContactModal || fromUserModal) && log.opportunity"
        @click="openOpportunity(log.opportunity.id)"
        class="info cursor-pointer"
      >
        Realizado na oportunidade:

        <div class="d-flex flex-row">
          <Avatar
            :hasStatus="false"
            :avatarLetter="$service.getFirstLetter(log.opportunity.name)"
            avatarColor="#FFFFFF"
            avatarBackground="#109CF1"
          />

          <div class="d-flex flex-column">
            <span class="name"> {{ log.opportunity.name }} </span>

            <span class="cod"> #{{ log.opportunity.id }} </span>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import Avatar from "../Avatar.vue";

export default {
  name: "OpportunityRecreated",

  components: {
    BCard,
    Avatar,
  },

  props: {
    log: {
      type: Object,
      default: null,
    },

    fromUserModal: {
      type: Boolean,
      default: false,
      required: false,
    },

    fromContactModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  methods: {
    openOpportunity(id) {
      this.$store.dispatch("userStore/setOpportunityForHistory", id).then(() => {
        this.$bvModal.show("bv-user-update_opportunity_modal");
      });
    },
  },
};
</script>

<style lang="scss">
.card {
  box-shadow: none;
  border: 1px solid #c4ccd8;
  border-radius: 10px;
  margin: 0 1em 1.5em;
  text-align: left;

  & > .card-body {
    padding: 0rem;

    .header {
      border-bottom: 1px solid #c4ccd8;
      padding: 0.5rem;
      flex-flow: row;
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4c5862;
      }
    }

    .footer {
      border-top: 1px solid #c4ccd8;
      padding: 0.5rem;
      flex-flow: row;
      display: flex;
      justify-content: space-between;

      .user {
        flex-flow: row;
        display: flex;

        .info {
          flex-flow: column;
          display: flex;

          .cod {
            color: #afafaf;
            font-size: 0.85em;
          }
        }
      }
    }

    .body {
      padding: 0 0.5em;
      h3 {
        font-weight: 500;
        font-size: 1em;
        line-height: 24px;
        color: #046af3;
        margin: 0px;
      }

      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        color: #000000;
        margin: 0px;
      }

      .info-container {
        display: flex;
        flex-flow: row;
      }
    }
  }
}
</style>
