const initialState = () => ({
  keep_filters: false,

  filters: {
    start_timestamp: null,
    end_timestamp: null,
    user: [],
    user_id: [],
    sort: "start_timestamp",
    task_status: null,
    media_id: null,
    campaign_id: null,
    onlyDelayed: false,
    type: null,
    parent_id: null,
  },

  task_type: [
    { id: "Action", title: "Ações" },
    { id: "Routine", title: "Rotinas" },
  ],

  task_status: [
    { id: "planned", title: "Planejadas" },
    { id: "changed", title: "Alteradas" },
    { id: "executed", title: "Execultadas" },
    { id: "failed", title: "Falhas" },
  ],

  total_ordering: [
    { id: "start_timestamp", title: "Por horário" },
    { id: "created_at", title: "Por criação" },
    { id: "updated_at", title: "Por atualização" },
  ],
});

const getters = {
  getFilters(state) {
    return state.filters;
  },

  getTaskType(state) {
    return state.task_type;
  },

  getTaskStatus(state) {
    return state.task_status;
  },

  getTotalOrdering(state) {
    return state.total_ordering;
  },

  setKeepFilters(state, value) {
    state.keep_filters = value;
  },

  haveAnyActiveFilters(state) {
    if (state.filters.start_timestamp) {
      return true;
    }

    if (state.filters.end_timestamp) {
      return true;
    }

    if (state.filters.user_id.length > 0) {
      return true;
    }

    if (state.filters.sort !== "start_timestamp") {
      return true;
    }

    if (state.filters.media_id) {
      return true;
    }

    if (state.filters.campaign_id) {
      return true;
    }

    if (state.filters.onlyDelayed) {
      return true;
    }

    if (state.filters.type) {
      return true;
    }

    if (state.filters.parent_id) {
      return true;
    }

    if (state.filters.type) {
      return true;
    }

    if (state.filters.status) {
      return true;
    }

    return false;
  },
};

const actions = {
  resetState({ state }) {
    const initial = initialState();

    if (!state.keep_filters) {
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  cleanValues({ state }) {
    if (!state.keep_filters) {
      state.filters = {
        start_timestamp: null,
        end_timestamp: null,
        user: [],
        user_id: [],
        sort: "-start_timestamp",
        task_type: "all",
        task_status: "all",
        media_id: null,
        campaign_id: null,
        onlyDelayed: false,
        type: null,
        parent_id: null,
      };

      return state.filters;
    }
  },

  setFilters({ state }, filters) {
    state.filters = filters;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};
