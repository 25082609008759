<template>
  <div class="outter-box">
    <div class="preview-holder">
      <div class="message-card">
        <div class="triangle" />

        <div
          v-if="header && header.format == 'TEXT'"
          v-html="$service.formattedHtmlText(changeBracketsForVariableInText(header))"
          class="template-header-text"
        />

        <div v-if="header && header.format == 'VIDEO'" class="template-header-midia">
          <video @canplay="onVideoCanPlay" :src="headerUrl" controls />

          <div v-if="isVideoLoading" class="floating-spinner">
            <b-spinner />
          </div>
        </div>

        <div v-if="header && header.format == 'IMAGE'" class="template-header-midia">
          <img v-if="headerUrl" :src="headerUrl" alt="template-img" />

          <div v-else class="placeholder-image" />
        </div>

        <div
          v-if="body"
          v-html="$service.formattedHtmlText(changeBracketsForVariableInText(body))"
          class="template-body"
        />

        <div v-if="!footer" class="template-time">
          {{ currentTime }}
        </div>

        <div
          v-if="footer"
          v-html="$service.formattedHtmlText(changeBracketsForVariableInText(footer))"
          class="template-footer"
        />

        <div v-if="footer" class="template-time">
          {{ currentTime }}
        </div>

        <div
          v-if="buttons.length"
          v-for="(button, index) in buttons"
          :key="index"
          class="template-buttons"
        >
          <feather-icon :icon="getIconByType(button.sub_type)" size="22" />

          <div>
            {{ button.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import moment from "moment";

export default {
  props: {
    templateDataProp: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },

    isVideoLoading: {
      type: Boolean,
      required: false,
      default: null,
    },
  },

  components: {
    BSpinner,
  },

  computed: {
    header() {
      return this.templateData.components.find((component) => component.type === "HEADER");
    },

    body() {
      return this.templateData.components.find((component) => component.type === "BODY");
    },

    footer() {
      return this.templateData.components.find((component) => component.type === "FOOTER");
    },

    buttons() {
      return this.templateData.components.filter((component) => component.type === "Button");
    },

    headerUrl() {
      if (!this.header?.parameters?.length) return null;

      const fullUrl = this.header.parameters[0].base64;

      return fullUrl || null;
    },
  },

  data() {
    return {
      templateData: this.templateDataProp,

      regex: /\{\{\d+\}\}/g,
      hourFormat: "HH:mm",

      currentTime: moment().format("HH:mm"),
    };
  },

  methods: {
    onVideoCanPlay() {
      this.$emit("videoLoaded");
    },

    getIconByType(type) {
      const allPossibleTypes = {
        QuickReply: "CornerUpLeftIcon",
        Url: "ExternalLinkIcon",
        CopyCode: "CopyIcon",
        PhoneNumber: "PhoneIcon",
      };

      const icon = allPossibleTypes[type];

      return icon || "ExternalLinkIcon";
    },

    changeBracketsForVariableInText(component) {
      const variables = component.text.match(this.regex);

      if (!variables) return component.text;

      let parsedText = component.text;

      variables.forEach((element, index) => {
        const regex = new RegExp(this.escapeRegExp(element), "g");

        parsedText = parsedText.replace(
          regex,
          component.parameters[index].text || `{{${index + 1}}}`
        );
      });

      component.parsedText = parsedText;

      return component.parsedText;
    },

    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },
  },
};
</script>

<style lang="scss" scoped>
.outter-box {
  height: fit-content;
  width: 100%;
  padding: 15px 20px;

  .preview-holder {
    min-height: fit-content;
    width: 100%;
    padding: 15px 15px 15px 20px;
    border-radius: 10px;
    background: url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png")
      no-repeat center center;
    background-size: cover;

    .message-card {
      display: flex;
      flex-direction: column;
      position: relative;
      word-wrap: break-word;
      width: 100%;
      padding: 10px;
      gap: 10px;
      border-radius: 8px;
      border-top-left-radius: 0;
      background-color: #ffffff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      .triangle {
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #ffffff;
        border-top-left-radius: 2px;
      }

      .template-header-text {
        font-size: 15px;
        font-weight: bold;
        line-height: 19px;
        overflow-wrap: break-word;
        text-align: initial;
        color: rgba(0, 0, 0, 0.76);
      }

      .template-header-midia {
        position: relative;

        video {
          width: 100%;
          height: auto;
          max-height: 450px;
          border-radius: 6px;
        }

        img {
          width: 100%;
          height: auto;
          max-height: 450px;
          border-radius: 6px;
        }

        .placeholder-image {
          width: 100%;
          height: 100px;
          border-radius: 6px;
          background: #f0f0f0;
        }

        .floating-spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .template-body {
        text-align: initial;
        font-size: 14px;
        overflow-wrap: break-word;
        color: #282828;
      }

      .template-footer {
        font-size: 13px;
        line-height: 17px;
        padding: 2px 0;
        color: rgba(0, 0, 0, 0.45);
      }

      .template-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 100%;
        padding: 10px 0 5px 0;
        gap: 5px;
        border-top: 1px solid #dadde1;
        color: #00a5f4;
      }

      .template-time {
        text-align: right;
        font-size: 11px;
        line-height: 15px;
        margin-top: -10px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
</style>
