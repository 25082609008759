<template>
  <div class="chat-container">
    <div class="top-menu">
      <i class="icon-close" @click="close" />
      <avatar-component
        class="avatar-component"
        avatarColor="#FFFFFF"
        :randomColor="opportunity.name"
        :avatarLetter="Array.from(opportunity.name)[0]"
        :size="1.9"
      />
      <p>
        {{
          opportunity.name
            ? opportunity.name
            : `Oportunidade #${opportunity.id}`
        }}
      </p>
      <div class="actions-container">
        <chat-button-component
          text="Editar"
          icon="icon-pencil-line"
          @click.native="toOpportunityForm(opportunity.id)"
        />
      </div>
    </div>
    <chats :opportunity="opportunity" />
  </div>
</template>

<script>
import ChatButton from "@/views/components/chat/ChatButton.vue";
import Avatar from "@/views/components/Avatar.vue";
import Chats from "@/views/components/chat/Chats.vue";

export default {
  name: "Chat",

  components: {
    "avatar-component": Avatar,
    "chat-button-component": ChatButton,
    Chats,
  },

  props: {
    opportunity: {
      type: Object,
      default: null,
      required: true,
    },
    fromOpportunity: {
      type: Boolean,
      default: false,
      required: false,
    }
  },

  data() {
    return {
      hasRequest: false,
    };
  },

  methods: {
    close() {
      this.$bvModal.hide("bv-create_chat_modal");
      this.$emit("closed");
    },

    toOpportunityForm(id) {
      if (this.fromOpportunity) {
        this.$emit("closed");
      } else {
        this.$emit("clickEditOpportunityForm", id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  .top-menu {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    height: 50px;
    position: fixed;
    background: #fff;
    width: 500px;
    border-bottom: 1px solid #e5e5e5;
    z-index: 2000;
    @media (max-width: 500px) {
      width: 100vw;
    }
    i {
      color: #14223b;
      cursor: pointer;
    }
    .avatar-component {
      margin-left: 1.5rem;
    }
    p {
      color: #000;
      font-weight: 500;
      margin-bottom: 0 !important;
    }
    .actions-container {
      margin-left: auto;
    }
  }
}
</style>
