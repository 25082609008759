import { instance } from "../../config/index";

const initialState = () => ({
  selectedTabIdLists: "campaign",

  hasAnySelected: false,

  showToast: false,
  toastInfo: {},

  hasUpdateResquest: false,

  tablesLimit: 50,

  editedCampaigns: [],
  campaignFilters: [],
  campaignOffset: 0,

  editedMedias: [],
  mediaFilters: [],
  mediaOffset: 0,

  editedProducts: [],
  productFilters: [],
  productOffset: 0,

  editedActionTypes: [],
  actionTypesFilters: [],
  actionTypesOffset: 0,

  editedInteractionTypes: [],
  interactionTypesFilters: [],
  interactionTypesOffset: 0,

  editedQuickAnswers: [],
  quickAnswersFilters: [],
  quickAnswersOffset: 0,

  editedReasonsLost: [],
  reasonsLostFilters: [],
  reasonsLostOffset: 0,

  editedDigitalEmployees: [],
  digitalEmployeesFilters: [],
  digitalEmployeesOffset: 0,

  editedTags: [],
  tagsFilters: [],
  tagsOffset: 0,

  editedSalesStages: [],
  salesStageFilters: [],
  salesStageOffset: 0,

  editedWorkspaces: [],
  workspaceFilters: [],
  workspaceOffset: 0,

  workspaceInactiveFilters: [],
  workspaceInactiveOffset: 0,

  userFilters: [],
  userOffset: 0,

  contactsFilters: [],
  contactsOffset: 0,

  batchMessagingFilters: [],
  batchMessagingOffset: 0,

  terms: {
    salesRep: "Atendente",
  },
});

const getters = {
  getTerms(state) {
    return state.terms;
  },

  isUpdating(state) {
    return state.hasUpdateResquest;
  },

  showToast(state) {
    return state.showToast;
  },

  toastInfo(state) {
    return state.toastInfo;
  },

  getSelectedTabLists(state) {
    return state.selectedTabIdLists;
  },

  getLimit(state) {
    return state.tablesLimit;
  },

  getEditedCampaigns(state) {
    return state.editedCampaigns;
  },

  getCampaignFilters(state) {
    return state.campaignFilters;
  },

  getCampaignOffset(state) {
    return state.campaignOffset;
  },

  getEditedMedias(state) {
    return state.editedMedias;
  },

  getMediaFilters(state) {
    return state.mediaFilters;
  },

  getMediaOffset(state) {
    return state.mediaOffset;
  },

  getEditedProducts(state) {
    return state.editedProducts;
  },

  getProductFilters(state) {
    return state.productFilters;
  },

  getProductOffset(state) {
    return state.productOffset;
  },

  getEditedActionTypes(state) {
    return state.editedActionTypes;
  },

  getActionTypesFilters(state) {
    return state.actionTypesFilters;
  },

  getActionTypesOffset(state) {
    return state.actionTypesOffset;
  },

  getEditedInteractionTypes(state) {
    return state.editedInteractionTypes;
  },

  getInteractionTypesFilters(state) {
    return state.interactionTypesFilters;
  },

  getInteractionTypesOffset(state) {
    return state.interactionTypesOffset;
  },

  getEditedQuickAnswers(state) {
    return state.editedQuickAnswers;
  },

  getQuickAnswersFilters(state) {
    return state.quickAnswersFilters;
  },

  getQuickAnswersOffset(state) {
    return state.quickAnswersOffset;
  },

  getEditedReasonsLost(state) {
    return state.editedReasonsLost;
  },

  getReasonsLostFilters(state) {
    return state.reasonsLostFilters;
  },

  getReasonsLostOffset(state) {
    return state.reasonsLostOffset;
  },

  getEditedDigitalEmployees(state) {
    return state.editedDigitalEmployees;
  },

  getDigitalEmployeesFilters(state) {
    return state.digitalEmployeesFilters;
  },

  getDigitalEmployeesOffset(state) {
    return state.digitalEmployeesOffset;
  },

  getEditedTags(state) {
    return state.editedTags;
  },

  getTagsFilters(state) {
    return state.tagsFilters;
  },

  getTagsOffset(state) {
    return state.tagsOffset;
  },

  getEditedSalesStages(state) {
    return state.editedSalesStages;
  },

  getSalesStagesFilters(state) {
    return state.salesStageFilters;
  },

  getSalesStagesOffset(state) {
    return state.salesStageOffset;
  },

  getEditedWorkspaces(state) {
    return state.editedWorkspaces;
  },

  getWorkspaceFilters(state) {
    return state.workspaceFilters;
  },

  getWorkspaceOffset(state) {
    return state.workspaceOffset;
  },

  getWorkspaceInactiveFilters(state) {
    return state.workspaceInactiveFilters;
  },

  getWorkspaceInactiveOffset(state) {
    return state.workspaceInactiveOffset;
  },

  getUserFilters(state) {
    return state.userFilters;
  },

  getUserOffset(state) {
    return state.userOffset;
  },

  getContactsFilters(state) {
    return state.contactsFilters;
  },

  getContactsOffset(state) {
    return state.contactsOffset;
  },

  getBatchMessagingFilters(state) {
    return state.batchMessagingFilters;
  },

  getBatchMessagingOffset(state) {
    return state.batchMessagingOffset;
  },
};

const actions = {
  selectTabLists({ state }, tab) {
    state.selectedTabIdLists = tab;
  },

  setIsUpdating({ state }, boolean) {
    state.hasUpdateResquest = boolean;
  },

  setEditedCampaigns({ state }, editedList) {
    state.editedCampaigns = editedList;
  },

  setCampaignFilters({ state }, filters) {
    state.campaignFilters = filters;
  },

  setCampaignOffset({ state }, offset) {
    state.campaignOffset = offset;
  },

  setEditedMedias({ state }, editedList) {
    state.editedMedias = editedList;
  },

  setMediaFilters({ state }, filters) {
    state.mediaFilters = filters;
  },

  setMediaOffset({ state }, offset) {
    state.mediaOffset = offset;
  },

  setEditedProducts({ state }, editedList) {
    state.editedProducts = editedList;
  },

  setProductFilters({ state }, filters) {
    state.productFilters = filters;
  },

  setProductOffset({ state }, offset) {
    state.productOffset = offset;
  },

  setEditedActionTypes({ state }, editedList) {
    state.editedActionTypes = editedList;
  },

  setActionTypesFilters({ state }, filters) {
    state.actionTypesFilters = filters;
  },

  setActionTypesOffset({ state }, offset) {
    state.actionTypesOffset = offset;
  },

  setEditedInteractionTypes({ state }, editedList) {
    state.editedInteractionTypes = editedList;
  },

  setInteractionTypesFilters({ state }, filters) {
    state.interactionTypesFilters = filters;
  },

  setInteractionTypesOffset({ state }, offset) {
    state.interactionTypesOffset = offset;
  },

  setEditedQuickAnswers({ state }, editedList) {
    state.editedQuickAnswers = editedList;
  },

  setQuickAnswersFilters({ state }, filters) {
    state.quickAnswersFilters = filters;
  },

  setQuickAnswersOffset({ state }, offset) {
    state.quickAnswersOffset = offset;
  },

  setEditedReasonsLost({ state }, editedList) {
    state.editedReasonsLost = editedList;
  },

  setReasonsLostFilters({ state }, filters) {
    state.reasonsLostFilters = filters;
  },

  setReasonsLostOffset({ state }, offset) {
    state.reasonsLostOffset = offset;
  },

  setEditedDigitalEmployees({ state }, editedList) {
    state.editedDigitalEmployees = editedList;
  },

  setDigitalEmployeesFilters({ state }, filters) {
    state.digitalEmployeesFilters = filters;
  },

  setDigitalEmployeesOffset({ state }, offset) {
    state.digitalEmployeesOffset = offset;
  },

  setEditedTags({ state }, editedList) {
    state.editedTags = editedList;
  },

  setTagsFilters({ state }, filters) {
    state.tagsFilters = filters;
  },

  setTagsOffset({ state }, offset) {
    state.tagsOffset = offset;
  },

  setEditedSalesStages({ state }, editedList) {
    state.editedSalesStages = editedList;
  },

  setSalesStageFilters({ state }, filters) {
    state.salesStageFilters = filters;
  },

  setSalesStageOffset({ state }, offset) {
    state.salesStageOffset = offset;
  },

  setEditedWorkspaces({ state }, editedList) {
    state.editedWorkspaces = editedList;
  },

  setWorkspaceFilters({ state }, filters) {
    state.workspaceFilters = filters;
  },

  setWorkspaceOffset({ state }, offset) {
    state.workspaceOffset = offset;
  },

  setWorkspaceInactiveFilters({ state }, filters) {
    state.workspaceInactiveFilters = filters;
  },

  setWorkspaceInactiveOffset({ state }, offset) {
    state.workspaceInactiveOffset = offset;
  },

  setUserFilters({ state }, filters) {
    state.userFilters = filters;
  },

  setUserOffset({ state }, offset) {
    state.userOffset = offset;
  },

  setContactsFilters({ state }, filters) {
    state.contactsFilters = filters;
  },

  setContactsOffset({ state }, offset) {
    state.contactsOffset = offset;
  },

  setBatchMessagingFilters({ state }, filters) {
    state.batchMessagingFilters = filters;
  },

  setBatchMessagingOffset({ state }, offset) {
    state.batchMessagingOffset = offset;
  },

  //////////////////////////////////////

  clearToast({ state }) {
    state.showToast = false;
    state.toastInfo = {};
  },

  useToast({ state }, info) {
    state.toastInfo = info;
    state.showToast = true;

    setTimeout(() => {
      state.showToast = false;
      state.toastInfo = {};
    }, 3000);
  },

  //////////////////////////////////////

  clearEditionLists({ state }) {
    state.editedCampaigns = [];
    state.editedMedias = [];
    state.editedProducts = [];
    state.editedActionTypes = [];
    state.editedInteractionTypes = [];
    state.editedQuickAnswers = [];
    state.editedReasonsLost = [];
    state.editedDigitalEmployees = [];
    state.editedTags = [];
    state.editedSalesStages = [];
    state.editedWorkspaces = [];
  },

  updateEditionList({ state, dispatch }, listInfo) {
    const allEditedLists = {
      campaign: (cell) => {
        const list = state.editedCampaigns.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedCampaigns", list);
      },

      media: (cell) => {
        const list = state.editedMedias.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedMedias", list);
      },

      product: (cell) => {
        const list = state.editedProducts.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedProducts", list);
      },

      actionTypes: (cell) => {
        const list = state.editedActionTypes.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedActionTypes", list);
      },

      interactionTypes: (cell) => {
        const list = state.editedInteractionTypes.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedInteractionTypes", list);
      },

      quickAnswers: (cell) => {
        const list = state.editedQuickAnswers.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedQuickAnswers", list);
      },

      reasonsLost: (cell) => {
        const list = state.editedReasonsLost.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedReasonsLost", list);
      },

      digitalEmployee: (cell) => {
        const list = state.editedDigitalEmployees.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedDigitalEmployees", list);
      },

      tags: (cell) => {
        const list = state.editedTags.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedTags", list);
      },

      stages: (cell) => {
        const list = state.editedSalesStages.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedSalesStages", list);
      },

      workspace: (cell) => {
        const list = state.editedWorkspaces.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedWorkspaces", list);
      },
    };

    allEditedLists[listInfo.id](listInfo.cell);
  },

  saveAllEdits({ state, dispatch }, toastInfo) {
    dispatch("setIsUpdating", true);

    const campaignEditPromises = state.editedCampaigns.map((element) => {
      return dispatch("updateCampaign", element);
    });

    const mediaEditPromises = state.editedMedias.map((element) => {
      return dispatch("updateMedia", element);
    });

    const productEditPromises = state.editedProducts.map((element) => {
      return dispatch("updateProduct", element);
    });

    const actionTypesPromises = state.editedActionTypes.map((element) => {
      return dispatch("updateActionType", element);
    });

    const interactionTypesPromises = state.editedInteractionTypes.map((element) => {
      return dispatch("updateInteractionType", element);
    });

    const quickAnswersPromises = state.editedQuickAnswers.map((element) => {
      return dispatch("updateQuickAnswer", element);
    });

    const reasonsLostPromises = state.editedReasonsLost.map((element) => {
      return dispatch("updateReasonsLost", element);
    });

    const digitalEmployeesPromises = state.editedDigitalEmployees.map((element) => {
      return dispatch("createDigitalEmployee", element);
    });

    const tagsPromises = state.editedTags.map((element) => {
      return dispatch("updateTags", element);
    });

    const saleStagesPromises = state.editedSalesStages.map((element) => {
      return dispatch("updateSaleStages", element);
    });

    const workspacePromises = state.editedWorkspaces.map((element) => {
      return dispatch("updateWorkspace", element);
    });

    const updatePromises = [
      ...campaignEditPromises,
      ...mediaEditPromises,
      ...productEditPromises,
      ...actionTypesPromises,
      ...interactionTypesPromises,
      ...quickAnswersPromises,
      ...reasonsLostPromises,
      ...digitalEmployeesPromises,
      ...tagsPromises,
      ...saleStagesPromises,
      ...workspacePromises,
    ];

    return Promise.all(updatePromises)
      .then((response) => {
        dispatch("useToast", toastInfo);

        return response;
      })
      .finally(() => {
        dispatch("clearEditionLists");
        dispatch("setIsUpdating", false);
      });
  },

  //////////////////////////////////////

  clearFilters({ state }) {
    state.campaignFilters = [];
    state.mediaFilters = [];
    state.productFilters = [];
    state.actionTypesFilters = [];
    state.interactionTypesFilters = [];
    state.quickAnswersFilters = [];
    state.reasonsLostFilters = [];
    state.digitalEmployeesFilters = [];
    state.tagsFilters = [];
    state.salesStageFilters = [];
    state.workspaceFilters = [];
    state.workspaceInactiveFilters = [];
    state.userFilters = [];
    state.contactsFilters = [];
    state.batchMessagingFilters = [];

    state.campaignOffset = 0;
    state.mediaOffset = 0;
    state.productOffset = 0;
    state.actionTypesOffset = 0;
    state.interactionTypesOffset = 0;
    state.quickAnswersOffset = 0;
    state.reasonsLostOffset = 0;
    state.digitalEmployeesOffset = 0;
    state.tagsOffset = 0;
    state.salesStageOffset = 0;
    state.workspaceOffset = 0;
    state.workspaceInactiveOffset = 0;
    state.userOffset = 0;
    state.contactsOffset = 0;
    state.batchMessagingOffset = 0;
  },

  clearOffSet({ state }) {
    state.campaignFilters = state.campaignFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.mediaFilters = state.mediaFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.productFilters = state.productFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.actionTypesFilters = state.actionTypesFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.interactionTypesFilters = state.interactionTypesFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.quickAnswersFilters = state.quickAnswersFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.reasonsLostFilters = state.reasonsLostFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.digitalEmployeesFilters = state.digitalEmployeesFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.tagsFilters = state.tagsFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.salesStageFilters = state.salesStageFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.workspaceFilters = state.workspaceFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.workspaceInactiveFilters = state.workspaceInactiveFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.userFilters = state.userFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.contactsFilters = state.contactsFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.batchMessagingFilters = state.batchMessagingFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.campaignOffset = 0;
    state.mediaOffset = 0;
    state.productOffset = 0;
    state.actionTypesOffset = 0;
    state.interactionTypesOffset = 0;
    state.quickAnswersOffset = 0;
    state.reasonsLostOffset = 0;
    state.digitalEmployeesOffset = 0;
    state.tagsOffset = 0;
    state.salesStageOffset = 0;
    state.workspaceOffset = 0;
    state.workspaceInactiveOffset = 0;
    state.userOffset = 0;
    state.contactsOffset = 0;
    state.batchMessagingOffset = 0;
  },

  //////////////////////////////////////

  fetchCampaigns({ state }, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/campaigns${params}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err.response));
    });
  },

  fetchMedias({ state }, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/medias${params}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err.response));
    });
  },

  fetchProducts({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/products${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchActionTypes({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/task-types${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchInteractionTypes({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/interaction-types${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchQuickAnswers({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/quick-answers${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchReasonsLost({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/objections${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchDigitalEmployees({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/digital-employees${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchTags({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/tags${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchSalesStages({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/sales-stages${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchWorkspaces({}, params) {
    const postUrl = `/users/self/workspaces${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchUsers({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/users${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchContacts({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/contacts${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchBatchMessaging({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/batch-messages${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //////////////////////////////////////

  fetchAutomationSettings({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/digital-employees/${id}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //////////////////////////////////////

  createCampaign({}, campaign) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/campaigns`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, campaign)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createMedia({}, media) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/medias`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, media)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createProduct({}, product) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/products`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, product)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createActionType({}, actionType) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/task-types`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, actionType)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createInteractionType({}, interactionType) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/interaction-types`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, interactionType)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createQuickAnswer({}, quickAnswer) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/quick-answers`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, quickAnswer)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createReasonsLost({}, reason) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/objections`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, reason)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createDigitalEmployee({}, employee) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/digital-employees`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, employee)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createTags({}, tag) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/tags`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, tag)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createSaleStages({}, stage) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/sales-stages`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, stage)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createWorkspace({}, workspace) {
    const postUrl = `/users/self/workspaces`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, workspace)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //////////////////////////////////////

  updateCampaign({}, campaign) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/campaigns/${campaign.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, campaign)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateMedia({}, media) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/medias/${media.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, media)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateProduct({}, product) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/products/${product.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, product)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateActionType({}, actionType) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/task-types/${actionType.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, actionType)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateInteractionType({}, interactionType) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/interaction-types/${interactionType.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, interactionType)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateQuickAnswer({}, quickAnswer) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/quick-answers/${quickAnswer.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, quickAnswer)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateReasonsLost({}, reason) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/objections/${reason.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, reason)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateTags({}, tag) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/tags/${tag.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, tag)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateSaleStages({}, stage) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/sales-stages/${stage.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, stage)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateWorkspace({}, workspace) {
    const putUrl = `/users/self/workspaces/${workspace.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, workspace)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //////////////////////////////////////

  deleteCampaign({}, campaign) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/campaigns/${campaign.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteMedia({}, media) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/medias/${media.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteProduct({}, product) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/products/${product.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteActionType({}, actionType) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/task-types/${actionType.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteInteractionType({}, interactionType) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/interaction-types/${interactionType.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteQuickAnswer({}, quickAnswer) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/quick-answers/${quickAnswer.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteReasonLost({}, reason) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/objections/${reason.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteDigitalEmployee({}, employee) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/digital-employees/${employee.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteTags({}, tag) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/tags/${tag.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteSalesStages({}, stage) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/sales-stages/${stage.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteWorkspace({}, workspace) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/users/self/workspaces/${workspaceId}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteUser({}, user) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/users/${user.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};

const mutations = {
  setHasAnySelected(state, boolean) {
    state.hasAnySelected = boolean;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
