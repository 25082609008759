const initialState = () => ({
  showCarousel: false,

  images: [],
  specificImage: null,
});

const getters = {
  getCarouselState(state) {
    return state.showCarousel;
  },

  getSpecificImage(state) {
    return state.specificImage;
  },

  getCarouselImages(state) {
    return state.images;
  },
};

const mutations = {};

const actions = {
  changeShowCarousel({ state }, boolean) {
    state.showCarousel = boolean;
  },

  setSpecificImage({ state }, image) {
    state.specificImage = image;
  },

  setCarouselImages({ state }, images) {
    state.images = images;
  },

  addImageToCarousel({ state }, image) {
    state.images.push(image);
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
