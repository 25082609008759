<template>
  <span class="d-flex flex-row" :class="variant">
    <span class="details">
      <span class="name">
        <label v-if="label" class="ml-1">{{ label }}</label>
        <span class="ml-1">{{
          inputVal ? inputVal.name : "Selecione o " + this.label
        }}</span>
      </span>
      <span class="detail" v-if="inputVal">Cód #{{ inputVal.id }}</span>
    </span>
    <div v-if="isActive">
      <i class="icon-check-circle" id="icon" />
    </div>
  </span>
</template>
<script>
export default {
  props: {
    workspace: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Object, Number],
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style scoped lang="scss">
.input {
  width: 100%;
  display: flex;
  padding: 4px;
  margin-bottom: 13px;
  border: 1px solid #d2d2d2;
  align-items: center;
  justify-content: left;
  border-radius: 4px;

  .details {
    flex-flow: row !important;

    .name {
      display: flex;
      flex-flow: column;

      label {
        max-width: fit-content;
        max-height: 1em;
        margin-bottom: 13px;
        margin-top: -17px;
        margin-left: -23px;
        font-size: 0.86em !important;
        background: #fff;
        color: #a39ba3;
        font-size: 1em;
      }
    }
    .detail {
      display: none;
    }
  }
}

.details {
  display: flex;
  flex-flow: column;
  .name {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    color: #4c5862;
  }
  .detail {
    text-align: left;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #999999;
  }
}
#icon {
  width: 15%;
  margin-top: 10px;
  font-size: 20px;
  color: #1172f3;
  right: 0;
  position: absolute;
}
</style>
