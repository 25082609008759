<template>
  <div class="contract-status">
    <div
      class="contract-top-row"
      :style="{
        borderLeft:
          '5px solid ' + getPaymentStatusColor(contract.payment_status),
      }"
    >
      <div style="display: flex; flex-direction: row; align-items: center">
        <i
          class="icon-upload-file"
          style="font-size: 30px; margin-right: 15px"
          :style="{
            color: getPaymentStatusColor(contract.payment_status),
          }"
        />

        <div style="display: flex; flex-direction: column">
          <span>
            Contrato <b>#{{ contract.id }}</b>
          </span>

          <span>
            Criado em
            {{ getContractDatas(contract.created_at) }}
          </span>
        </div>
      </div>

      <feather-icon icon="ChevronRightIcon" size="18" />
    </div>

    <div class="second-row">
      <i
        :class="getPaymentStatusIcon(contract.payment_status)"
        style="font-size: 24px; margin-right: 14px"
        :style="{
          color: getPaymentStatusColor(contract.payment_status),
        }"
      />

      <div class="enroll-status" style="margin-right: 8px">
        <div>
          <b>
            {{ getPaymentStatus(contract.payment_status) + ":" }}
          </b>

          <span style="margin-right: 5px">
            {{ $service.formatBrlAmountSymbol(contract.enrollment_fee) }}
          </span>
        </div>

        <span>
          {{
            "(Venc. " +
            $service.dateFormat(
              contract.payment_due_date,
              "YYYY-MM-DD HH:mm:ss",
              "DD/MM/YY"
            ) +
            ")"
          }}
        </span>
      </div>
    </div>

    <div class="third-row" v-if="dependent.isResponsable">
      <b>Dependente</b>

      <span> {{ dependent.studentName }} </span>

      <b>CPF do dependente</b>

      <span> {{ dependent.studentDocument }} </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    contract: {
      type: Object,
      required: true,
      default: null,
    },

    dependent: {
      type: Object,
      required: true,
      default: null,
    },
  },

  methods: {
    getPaymentStatus(status) {
      const allStatus = {
        Pending: "Matrícula Pendente",
        Succeeded: "Matrícula Efetivada",
        Canceled: "Matrícula Cancelada",
      };

      return allStatus[status];
    },

    getPaymentStatusIcon(status) {
      const allStatus = {
        Pending: "icon-task-clock",
        Succeeded: "icon-check-circle-outline",
        Canceled: "icon-close-circle-outline",
        0: "icon-task-clock",
      };

      return allStatus[status];
    },

    getPaymentStatusColor(status) {
      const allStatus = {
        Pending: "#F8AA00",
        Succeeded: "#046AF3",
        Canceled: "#FF2C55",
      };

      return allStatus[status];
    },

    getContractDatas(contact) {
      const data =
        this.$service.dateFormat(contact, "YYYY-MM-DD HH:mm:ss", "DD/MM/YY") +
        " às " +
        this.$service.dateFormat(contact, "YYYY-MM-DD HH:mm:ss", "HH:mm");

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-status {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 10px 0;

  .contract-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: solid 1px #c4ccd8;
    padding: 2px 10px;
  }

  .second-row {
    display: flex;
    align-items: center;
    padding: 8px 4px 5px 17px;
    margin-top: -3px;
    border: solid 1px #c4ccd8;
    border-top: none;

    .enroll-status {
      display: flex;
    }
  }

  .third-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px 1rem;
    border: solid 1px #c4ccd8;
    border-top: none;
  }

  @media (max-width: 800px) {
    .enroll-status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 80%;
    }
  }
}
</style>
