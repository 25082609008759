<template>
  <div class="chats-content">
    <b-tabs id="icons-menu">
      <b-tab
        id="icon"
        v-if="opportunity.campaign_id === 'Chatbot (Bella)'"
        active
      >
        <template slot="title">
          <i style="font-size: 25px" class="icon-imbot" id="icon-green" />
        </template>
        <chat-rocket :opportunity="opportunity" />
      </b-tab>

      <b-tab id="icon" v-else>
        <template slot="title">
          <i style="font-size: 25px" class="icon-imbot" id="icon-green" />
        </template>
        <chat-rocket :opportunity="opportunity" />
      </b-tab>

      <b-tab
        id="icon"
        v-if="opportunity.campaign_id !== 'Chatbot (Bella)'"
        active
      >
        <template slot="title">
          <i class="icon-whatsapp-chat-icon" id="icon-green" />
        </template>
        <chat-whatsapp :opportunity="opportunity" @whats="taskFromWhats" />
      </b-tab>

      <b-tab id="icon" v-else>
        <template slot="title">
          <i class="icon-whatsapp-chat-icon" id="icon-green" />
        </template>
        <chat-whatsapp :opportunity="opportunity" @whats="taskFromWhats" />
      </b-tab>

      <b-tab id="icon">
        <template slot="title">
          <i class="icon-phone-chat-icon" id="icon-blue" />
        </template>
        <chat-phone :opportunity="opportunity" @call="taskFromCall" />
      </b-tab>

      <!-- <b-tab id="icon">
        <template slot="title">
          <i class="icon-video-chat-icon" />
        </template>
      </b-tab> -->

      <b-tab id="icon">
        <template slot="title">
          <i class="icon-email-svgrepo-com" id="icon-blue" />
        </template>
        <chat-email :opportunity="opportunity" @email="taskFromEmail" />
      </b-tab>
    </b-tabs>

    <b-modal
      class="modal-dialog"
      id="bv-task_form"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="d-block text-center" v-if="opportunity">
        <task-form
          createdFrom="Opportunity"
          :opportunity="opportunity"
          :parentId="opportunity.id"
          :fromTaskWhatsapp="fromWhatsapp"
          :fromTaskCall="fromCall"
          :fromTaskEmail="fromEmail"
          @closed="hideModal('bv-task_form')"
          @saved="savedTaskForm()"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import ChatRocket from "@/views/components/chat/ChatRocket.vue";
import ChatWhatsapp from "@/views/components/chat/ChatWhatsapp.vue";
import ChatPhone from "@/views/components/chat/ChatPhone.vue";
import ChatEmail from "@/views/components/chat/ChatEmail.vue";
import TaskForm from "@/views/forms/TaskForm.vue";

export default {
  props: {
    opportunity: {
      type: Object,
      default: null,
      required: true,
    },

    isFromOmnichannelDesktop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fromWhatsapp: false,
      fromCall: false,
      fromEmail: false,
    };
  },

  components: {
    BTab,
    BTabs,
    ChatRocket,
    ChatWhatsapp,
    ChatPhone,
    ChatEmail,
    TaskForm,
  },

  methods: {
    taskFromWhats() {
      this.cleanValues();
      this.fromWhatsapp = true;
      this.openModal("bv-task_form");
    },

    taskFromCall() {
      this.cleanValues();
      this.fromCall = true;
      this.openModal("bv-task_form");
    },

    taskFromEmail() {
      this.cleanValues();
      this.fromEmail = true;
      this.openModal("bv-task_form");
    },

    openModal(modal) {
      this.$bvModal.show(modal);
    },

    hideModal(modal) {
      if (modal) {
        this.$bvModal.hide(modal);
        return;
      }

      this.$bvModal.hide("bv-task_for");
    },

    savedTaskForm() {
      this.$emit("finished");
      this.hideModal("bv-task_form");
    },

    cleanValues() {
      this.fromWhatsapp = false;
      this.fromCall = false;
      this.fromEmail = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.chats-content {
  :deep(#icons-menu__BV_tab_controls_) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 65px;
    border-bottom: 1px solid #e5e5e5;

    #icon___BV_tab_button__ {
      background: #f5f6f8;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      &.active {
        #icon-red {
          color: #ff2c55;
        }
        #icon-green {
          color: #2ed47a;
        }
        #icon-blue {
          color: #046af3;
        }
      }
      &:after {
        background: transparent !important;
      }
      i {
        color: black;
        margin-right: 0 !important;
        font-size: 18px;
      }
    }
    .nav-item:nth-child(1) a.active {
      border: 1px solid #2ed47a;
    }
    .nav-item:nth-child(2) a.active {
      border: 1px solid #2ed47a;
    }
    .nav-item:nth-child(3) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(4) a.active {
      border: 1px solid #046af3;
    }
  }
}
</style>
