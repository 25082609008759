/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  opportunity_for_contract: null,
});

const getters = {
  getOpportunityForContract(state) {
    return state.opportunity_for_contract;
  },
};

const mutations = {};

const actions = {
  // eslint-disable-next-line no-empty-pattern
  createContract({}, contract) {
    let workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/contracts`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, contract)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  listContracts({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/contracts${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  setOpportunityForContract({ state }, opportunity) {
    state.opportunity_for_contract = opportunity;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
