<template>
  <div class="chat-spinner-holder">
    <b-spinner v-if="hasScrollLoading || hasChatLoading" class="absolute-loading" />

    <div v-if="hasNoChat" class="no-chat-image">
      <img src="@/assets/images/whatsapp-illustration-gray.svg" alt="no-chat-image" />
    </div>

    <div
      v-else
      @scroll="checkScroll"
      :class="{ modalPadding: !fromOmnichannelDesktop }"
      class="chat-container"
      ref="chatContainer"
    >
      <div v-for="(message, index) in allChatData" :key="message.id" class="message-divider-holder">
        <div
          v-if="isOpeningMessage(allChatData[index + 1], message)"
          @click="openOpportunity(message)"
          class="line-and-chat-start cursor-pointer"
        >
          <div class="start-message">
            {{ message.dialog.bot_name }} - Sala n° {{ message.dialog_id }} - Oportunidade:
            {{ message?.dialog?.opportunity?.id }} -
            {{ getFormattedDate(message.sent_at, true) }}
          </div>
        </div>

        <div
          v-else-if="isPreviousMessageFromDifferentDay(message, allChatData[index + 1])"
          class="line-and-chat-start"
        >
          <div class="start-message">
            {{ getFormattedDate(message.sent_at) }}
          </div>
        </div>

        <div
          :style="getHolderStyleDependingOnSender(message, allChatData[index - 1])"
          class="message-holder"
        >
          <div v-if="getMimeType(message?.file?.mime) == 'audio'" style="position: relative">
            <IOSAudioPlayer v-if="isIOS()" :id="'message-' + message.id" :src="message.file.url" />

            <audio v-else :id="'message-' + message.id" preload="auto" controls>
              <source :src="message.file.url" :type="message.file.mime" />
            </audio>

            <time-view-check
              :message="message"
              :style="`bottom: ${isIOS() ? '4' : '9'}px`"
              class="absolute-time-check"
            />
          </div>

          <div
            v-else
            :id="'message-card-' + message.id"
            :style="getMessageStyleDependingOnSender(message, allChatData[index - 1])"
            class="message-card"
          >
            <div
              v-if="message.header"
              class="message-header"
              v-html="$service.formattedHtmlText(message.header)"
            />

            <div v-if="message.file" class="file-holder">
              <img
                v-if="getMimeType(message.file?.mime) == 'image'"
                @click="openCarouselModal(message.file)"
                :id="'message-' + message.id"
                :class="{ 'file-error': message.error_message }"
                :style="{ maxWidth: mediaMaxWidth }"
                :src="message.file.url"
                :alt="'image' + message.file.name"
                class="visual-file"
              />

              <div
                v-else-if="getMimeType(message.file?.mime) == 'sticker'"
                :id="'message-' + message.id"
                class="sticker-file"
              >
                Sticker ainda não suportado!
              </div>

              <video
                v-else-if="getMimeType(message.file?.mime) == 'video'"
                :id="'message-' + message.id"
                :class="{ 'file-error': message.error_message }"
                :style="{ maxWidth: mediaMaxWidth }"
                class="visual-file"
                controls
              >
                <source :src="message.file.url" />
                Seu navegador não suporta a reprodução de vídeos.
              </video>

              <a
                v-else-if="getMimeType(message.file?.mime) == 'document'"
                :id="'message-' + message.id"
                :class="{ 'file-error': message.error_message }"
                :href="message.file.url"
                class="document-file"
                target="_blank"
              >
                <div class="file-name">{{ message.file.name }}</div>

                <i class="icon-arrow-down" />
              </a>
            </div>

            <div v-if="message.message" v-html="$service.formattedHtmlText(message.message)" />

            <div
              v-if="message.footer"
              v-html="$service.formattedHtmlText(message.footer)"
              class="message-footer"
            />

            <time-view-check :message="message" />
          </div>

          <div
            v-if="message.error_message"
            :id="'message-' + message.id"
            :style="message.user_id || message.bot_name ? 'margin-right: 0.5rem' : ''"
          >
            <feather-icon icon="AlertCircleIcon" color="#c01220" size="30" />
          </div>

          <avatar
            v-else-if="message.user_id"
            :class="{ invisible: isTheOtherMessageFromSameSender(message, chatData[index + 1]) }"
            :avatarLetter="$service.getFirstLetter(message.user?.name)"
            :size="2"
          />

          <img
            v-else-if="message.bot_name"
            :class="{ invisible: isTheOtherMessageFromSameSender(message, chatData[index + 1]) }"
            src="@/assets/images/icons/imbot-avatar.svg"
            alt="bot-icon"
            class="bot-avatar"
          />

          <b-tooltip
            v-if="message.error_message"
            :target="'message-' + message.id"
            triggers="hover"
            placement="top"
          >
            {{ message.error_message }}
          </b-tooltip>
        </div>
      </div>
    </div>

    <b-modal
      v-if="!fromOmniRedirect"
      id="bv-opportunity-from-chat-redirect"
      class="modal-dialog"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
    >
      <opportunity-and-contacts
        v-if="opportunityFromRedirect"
        @saved="$emit('saved')"
        @closed="$emit('closed')"
        @refresh="$emit('refresh')"
        @emitLottieWin="$emit('emitLottieWin')"
        @roomReopened="$emit('roomReopened')"
        @opportunityRedirect="handleRedirectFromRedirect($event)"
        :opportunity="opportunityFromRedirect"
        :fromOmniRedirect="true"
      />
    </b-modal>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BSpinner, BTooltip } from "bootstrap-vue";
import { errorHandling } from "@/mixins";
import IOSAudioPlayer from "@/views/components/chat/IOSAudioPlayer.vue";
import moment from "moment";
import TimeViewCheck from "@/views/components/chat/TimeViewCheck.vue";

export default {
  components: {
    Avatar,
    BSpinner,
    BTooltip,
    IOSAudioPlayer,
    TimeViewCheck,

    OpportunityAndContacts: () => import("@/views/forms/OpportunityAndContacts.vue"),
  },

  props: {
    opportunity: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    fromOmnichannelDesktop: {
      type: Boolean,
      default: false,
      required: false,
    },

    fromOmniRedirect: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  mixins: [errorHandling],

  data() {
    return {
      newestChatId: null,
      currentPastChatId: null,

      mediaMaxWidth: null,
      resizeObserver: null,

      chatData: [],
      previousChatData: [],
      allPreviousChatData: [],

      pullingLoop: null,
      loopBreak: false,

      newestOffset: 0,
      currentPastOffset: 0,
      limit: 20,

      firstTotalCount: 0,
      currentTotalCount: 0,
      currentIndexOfAllPreviousChats: 0,

      hasChatLoading: false,
      hasScrollLoading: false,
      hasPullingLoading: false,
      hasOpReselectRequest: false,

      hasNoChat: false,
      hasPreviousChat: false,
      hasReachedStartOfCurrentConversation: false,

      opportunityFromRedirect: null,
    };
  },

  computed: {
    allChatData() {
      return [...this.chatData, ...this.allPreviousChatData];
    },

    oppReselectData() {
      return this.$store.getters["omnichannel/getOpportunityReselectData"];
    },
  },

  mounted() {
    this.getAllDialogs();

    this.setMediaWidthListener();
  },

  beforeDestroy() {
    this.loopBreak = true;

    this.stopPullingLoop();

    if (this.resizeObserver && this.$refs.chatContainer) {
      this.resizeObserver.unobserve(this.$refs.chatContainer);
    }
  },

  watch: {
    "opportunity.dialogs": {
      handler() {
        this.getAllDialogs();
      },

      deep: true,
    },
  },

  methods: {
    startPullingLoop() {
      if (this.hasNoChat || this.loopBreak) return;

      if (this.opportunity.dialogs[0]?.closed_at) return;

      if (this.pullingLoop) {
        clearInterval(this.pullingLoop);
      }

      this.pullingLoop = setInterval(() => {
        this.pullingFetch(this.newestChatId);
      }, 5000);
    },

    stopPullingLoop() {
      clearInterval(this.pullingLoop);
    },

    setMediaWidthListener() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;

        if (!chatContainer) return;

        this.resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            this.mediaMaxWidth = entry.contentRect.width - 75 + "px";
          }
        });

        this.resizeObserver.observe(chatContainer);
      });
    },

    checkIfChatHasScroll() {
      const chatContainer = this.$refs.chatContainer;

      if (chatContainer && chatContainer.scrollHeight > chatContainer.clientHeight) {
        return true;
      }

      return false;
    },

    checkIfHasScroll() {
      setTimeout(() => {
        this.$nextTick(() => {
          if (!this.checkIfChatHasScroll() && this.currentPastChatId) {
            this.hasReachedStartOfCurrentConversation = true;

            this.scrollFetch(this.currentPastChatId, true);
          }
        });
      }, 1000);
    },

    openCarouselModal(file) {
      this.$store.dispatch("modalCarousel/setSpecificImage", file.url);

      this.$store.dispatch("modalCarousel/changeShowCarousel", true);
    },

    setAllImages() {
      const allMessagesWithImages = this.allChatData.filter(
        (message) => message.file?.mime === "image/jpeg" || message.file?.mime === "image/png"
      );

      const allImages = [];

      if (allMessagesWithImages.length) {
        allMessagesWithImages.forEach((element) => {
          allImages.push(element.file.url);
        });
      }

      this.$store.dispatch("modalCarousel/setCarouselImages", allImages);
    },

    isIOS() {
      const userAgent = navigator.userAgent;

      return /iPhone|iPad|iPod/i.test(userAgent);
    },

    isOpeningMessage(previousMessage, currentMessage) {
      if (currentMessage.dialog?.id != previousMessage?.dialog?.id) {
        return true;
      }

      if (!this.hasReachedStartOfCurrentConversation || previousMessage) return false;

      return true;
    },

    handleOppReselectData(data) {
      for (const key in data) {
        if (data.hasOwnProperty(key) && this.hasOwnProperty(key)) {
          this[key] = data[key];
        }
      }

      this.$nextTick(() => {
        setTimeout(() => {
          const chatContainer = this.$refs.chatContainer;

          if (chatContainer) {
            chatContainer.scrollTo({
              top: data.scrollTop,
              behavior: "smooth",
            });
          }
        }, 500);
      });
    },

    getAllDialogs() {
      if (!this.opportunity?.dialogs) {
        this.hasNoChat = true;
        return;
      }

      this.hasNoChat = false;

      const allDialogs = this.opportunity.dialogs;

      this.newestChatId = allDialogs[0].id;

      if (allDialogs.length > 1) {
        this.hasPreviousChat = true;
        this.currentPastChatId = allDialogs[1].id;

        this.currentIndexOfAllPreviousChats = 1;
      }

      if (this.oppReselectData?.newestChatId == this.newestChatId) {
        this.handleOppReselectData(this.oppReselectData);
      } else {
        this.getFirstChatData(this.newestChatId);
      }

      this.$store.commit("omnichannel/CHANGE_OPPORTUNITY_RESELECT_DATA", null);
    },

    getNextPastChat() {
      if (this.currentIndexOfAllPreviousChats + 1 >= this.opportunity.dialogs.length) {
        this.hasPreviousChat = false;

        return;
      }

      this.currentIndexOfAllPreviousChats += 1;
      this.currentPastOffset = 0;
      this.previousChatData = [];
      this.currentPastChatId = this.opportunity.dialogs[this.currentIndexOfAllPreviousChats].id;
    },

    getFormattedDate(date, showTime = false) {
      const momentDate = moment.parseZone(date);
      const formatedDate = momentDate.format("DD/MM/YYYY HH:mm");
      const yesterday = moment().subtract(1, "days");

      if (momentDate.isSame(moment(), "day")) {
        return "Hoje" + (showTime ? " - " + momentDate.format("HH:mm") : "");
      } else if (momentDate.isSame(yesterday, "day")) {
        return "Ontem" + (showTime ? " - " + momentDate.format("HH:mm") : "");
      } else {
        return formatedDate;
      }
    },

    isPreviousMessageFromDifferentDay(currentMessage, previousMessage) {
      if (!previousMessage) return true;

      const currentMessageDate = moment.parseZone(currentMessage.sent_at);
      const previousMessageDate = moment.parseZone(previousMessage.sent_at);

      return !currentMessageDate.isSame(previousMessageDate, "day");
    },

    isTheOtherMessageFromSameSender(currentMessage, otherMessage) {
      if (!otherMessage) return false;

      if (currentMessage.bot_name) {
        return currentMessage.bot_name === otherMessage.bot_name;
      }

      if (currentMessage.user_id) {
        return currentMessage.user_id === otherMessage.user_id;
      }

      return !otherMessage.bot_name && !otherMessage.user_id;
    },

    getHolderStyleDependingOnSender(currentMessage, nextMessage) {
      const isSameSender = this.isTheOtherMessageFromSameSender(currentMessage, nextMessage);
      const style = { marginBottom: "12px" };

      if (currentMessage.user_id || currentMessage.bot_name) {
        style.justifyContent = "flex-end";
      }

      if (isSameSender) {
        style.marginBottom = "3px";
      }

      return style;
    },

    getMessageStyleDependingOnSender(currentMessage, nextMessage) {
      const isSameSender = this.isTheOtherMessageFromSameSender(currentMessage, nextMessage);

      const style = {};

      if (currentMessage.user_id || currentMessage.bot_name) {
        style.backgroundColor = "#dbedff";

        if (!isSameSender) {
          style.borderBottomRightRadius = "0px";
        }
      } else {
        style.backgroundColor = "#f5f6f8";

        if (!isSameSender) {
          style.borderBottomLeftRadius = "0px";
        }
      }

      if (currentMessage.error_message) {
        style.fontStyle = "italic";
        style.border = "1px solid #c01220";
        style.backgroundColor = "#f8d7da";
      }

      return style;
    },

    getMimeType(type) {
      const possibleTypes = {
        "audio/aac": "audio",
        "audio/mp4": "audio",
        "audio/amr": "audio",
        "audio/mpeg": "audio",
        "audio/ogg": "audio",
        "audio/mp3": "audio",
        "audio/wav": "audio",
        "audio/opus": "audio",

        "image/jpeg": "image",
        "image/png": "image",
        "image/gif": "image",
        "image/tiff": "image",

        "image/webp": "sticker",

        "video/mp4": "video",
        "video/3gpp": "video",

        "text/plain": "document",
        "text/csv": "document",
        "text/tsv": "document",
        "text/html": "document",
        "text/rtf": "document",

        "application/pdf": "document",
        "application/msword": "document",
        "application/vnd.ms-powerpoint": "document",
        "application/vnd.ms-excel": "document",
        "application/vnd.ms-access": "document",

        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "document",
        "application/xlsx": "document",
        "application/octet-stream": "document",

        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "document",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "document",

        "application/vnd.oasis.opendocument.text": "document",
        "application/vnd.oasis.opendocument.spreadsheet": "document",
        "application/vnd.oasis.opendocument.presentation": "document",

        "application/zip": "document",
        "application/x-7z-compressed": "document",
        "application/x-rar-compressed": "document",
        "application/gzip": "document",
        "application/x-tar": "document",

        "application/vnd.android.package-archive": "document",
        "application/vnd.apple.pkpass": "document",
        "application/vnd.adobe.photoshop": "document",
      };

      if (!possibleTypes[type]) {
        return "document";
      }

      let documentType = possibleTypes[type];

      return documentType;
    },

    scrollToPosition(position) {
      const chatContainer = this.$refs.chatContainer;

      if (!chatContainer) return;

      chatContainer.scrollTo({ top: position, behavior: "smooth" });
    },

    checkScroll(event) {
      const element = event.target;

      const scrollTop = Math.abs(element.scrollTop);
      const maxScrollTop = Math.abs(element.scrollHeight - element.clientHeight);

      const tolerance = 2;

      if (Math.abs(scrollTop - maxScrollTop) <= tolerance) {
        if (this.firstTotalCount > this.chatData.length) {
          this.scrollFetch(this.newestChatId);

          this.hasReachedStartOfCurrentConversation = false;

          return;
        }

        this.hasReachedStartOfCurrentConversation = true;

        if (this.hasPreviousChat && this.currentTotalCount >= this.previousChatData.length) {
          this.scrollFetch(this.currentPastChatId, true);
        }
      }
    },

    handleScrollData(response, fromPreviousChats = false) {
      const oldMessages = response.data;

      if (fromPreviousChats) {
        this.currentPastOffset += oldMessages.length;
        this.currentTotalCount = response.pagination.total_count;

        this.allPreviousChatData = [...this.allPreviousChatData, ...oldMessages];
        this.previousChatData = [...this.previousChatData, ...oldMessages];

        if (this.previousChatData.length >= this.currentTotalCount) {
          this.getNextPastChat();

          if (!this.checkIfChatHasScroll() && this.hasPreviousChat && !this.loopBreak) {
            this.scrollFetch(this.currentPastChatId, true);
          }
        }

        this.setAllImages();

        return;
      }

      const filteredArray = oldMessages.filter((obj1) => {
        return !this.chatData.some((obj2) => {
          return obj1.id === obj2.id;
        });
      });

      this.newestOffset += filteredArray.length;
      this.firstTotalCount = response.pagination.total_count;

      this.chatData = [...this.chatData, ...filteredArray];

      this.setAllImages();
    },

    handlePullingData(response) {
      const newMessages = response.data;

      const filteredArray = newMessages.filter((obj1) => {
        return !this.chatData.some((obj2) => {
          return obj1.id === obj2.id;
        });
      });

      this.newestOffset += filteredArray.length;
      this.firstTotalCount = response.pagination.total_count;

      this.chatData = [...filteredArray, ...this.chatData];

      if (newMessages?.length) {
        newMessages.forEach((newElement) => {
          this.chatData.forEach((element, i) => {
            if (element.id == newElement.id) {
              let updatedElement = { ...element, ...newElement };

              this.$set(this.chatData, i, updatedElement);

              return;
            }
          });
        });
      }

      this.setAllImages();
    },

    handleRedirectFromRedirect(data) {
      if (!this.fromOmniRedirect) {
        this.handleOppReselectData(this.oppReselectData);

        if (data.id == this.opportunity.id) {
          const position = this.oppReselectData?.scrollTop;

          this.scrollToPosition(position);

          this.loopBreak = false;

          this.startPullingLoop();

          return;
        }

        this.opportunityFromRedirect = data;

        this.$nextTick(() => {
          this.$bvModal.show("bv-opportunity-from-chat-redirect");
        });
      }
    },

    getFirstChatData(id) {
      this.hasChatLoading = true;

      const params = [
        { query: "limit", param: this.limit },
        { query: "offset", param: this.newestOffset },
      ];

      const data = {
        chat_id: id,

        params: this.$service.formatQueryParam(params),
      };

      this.$store
        .dispatch("omnichannel/getChat", data)
        .then((response) => {
          this.chatData = response.data;

          this.newestOffset += this.chatData.length;
          this.firstTotalCount = response.pagination.total_count;

          if (this.chatData.length >= this.firstTotalCount) {
            this.hasReachedStartOfCurrentConversation = true;
          }

          this.startPullingLoop();
          this.setAllImages();
          this.checkIfHasScroll();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.hasChatLoading = false;
        });
    },

    scrollFetch(id, isFromPreviousChats = false) {
      if (this.hasScrollLoading) return;

      this.hasScrollLoading = true;

      const params = [
        { query: "limit", param: this.limit },

        {
          query: "offset",
          param: isFromPreviousChats ? this.currentPastOffset : this.newestOffset,
        },
      ];

      const data = {
        chat_id: id,

        params: this.$service.formatQueryParam(params),
      };

      this.$store
        .dispatch("omnichannel/getChat", data)
        .then((response) => {
          this.hasScrollLoading = false;

          this.handleScrollData(response, isFromPreviousChats);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.hasScrollLoading = false;
        });
    },

    pullingFetch(id) {
      if (this.hasPullingLoading || this.hasScrollLoading) return;

      this.hasPullingLoading = true;

      const params = [
        { query: "limit", param: this.limit },
        { query: "offset", param: 0 },
      ];

      const data = {
        chat_id: id,

        params: this.$service.formatQueryParam(params),
      };

      this.$store
        .dispatch("omnichannel/getChat", data)
        .then((response) => {
          if (response.data.length) {
            this.handlePullingData(response);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.hasPullingLoading = false;
        });
    },

    openOpportunity(message) {
      if (this.hasOpReselectRequest) return;

      this.hasOpReselectRequest = true;

      const opp_id = message?.dialog?.opportunity?.id;

      if (opp_id == this.opportunity.id) {
        this.hasOpReselectRequest = false;

        return;
      }

      if (opp_id) {
        this.$store
          .dispatch("opportunityStore/fetchOne", opp_id)
          .then((response) => {
            this.opportunityFromRedirect = response.data;

            const scrollContainer = this.$refs.chatContainer;

            const emitData = {
              newestChatId: this.newestChatId,
              currentPastChatId: this.currentPastChatId,

              chatData: this.chatData,
              previousChatData: this.previousChatData,
              allPreviousChatData: this.allPreviousChatData,

              newestOffset: this.newestOffset,
              currentPastOffset: this.currentPastOffset,

              firstTotalCount: this.firstTotalCount,
              currentTotalCount: this.currentTotalCount,
              currentIndexOfAllPreviousChats: this.currentIndexOfAllPreviousChats,

              hasNoChat: this.hasNoChat,
              hasPreviousChat: this.hasPreviousChat,
              hasReachedStartOfCurrentConversation: this.hasReachedStartOfCurrentConversation,

              scrollTop: scrollContainer?.scrollTop,
            };

            this.$store.commit("omnichannel/CHANGE_OPPORTUNITY_RESELECT_DATA", emitData);

            if (this.fromOmnichannelDesktop) {
              this.$emit("selectOpFromOmnichat", response.data);

              return;
            }

            this.loopBreak = true;
            this.stopPullingLoop();

            if (this.fromOmniRedirect) {
              this.$bvModal.hide("bv-opportunity-from-chat-redirect");

              this.$nextTick(() => {
                this.$emit("opportunityRedirect", response.data);
              });

              return;
            }

            this.$bvModal.show("bv-opportunity-from-chat-redirect");
          })
          .catch((error) => {
            if (error?.response?.data?.status == 403) {
              this.MIXIN_showError(
                { message: "Você não tem permissão para acessar essa oportunidade." },
                403
              );

              return;
            }

            this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
          })
          .finally(() => {
            this.hasOpReselectRequest = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-spinner-holder {
  position: relative;

  .absolute-loading {
    position: absolute;
    top: -20px;
    left: calc(50% - 18px);
    z-index: 2000;
  }

  .no-chat-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 145px);
    width: 100%;

    img {
      width: 100%;
    }
  }

  .modalPadding {
    padding-bottom: 63px;
  }

  .chat-container {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 145px);
    width: 100%;
    gap: 3px;
    padding-top: 10px;
    overflow-y: scroll;

    .message-divider-holder {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 5px;

      .line-and-chat-start {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1px;
        width: 100%;
        margin: 25px 0;
        background: #e5e5e5;

        .start-message {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          max-width: 90%;
          padding: 4px 15px;
          border-radius: 800px;
          background: #c4e2ff;
          color: #192a3e;
        }
      }

      .message-holder {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 10px;
        gap: 5px;

        .absolute-time-check {
          position: absolute;
          right: 18px;
        }

        .message-card {
          display: flex;
          flex-direction: column;
          text-align: left;
          max-width: 500px;
          gap: 5px;
          padding: 10px;
          border-radius: 16px;
          color: #000000;
          overflow: hidden;

          .message-header {
            font-size: 15px;
            font-weight: bold;
            overflow-wrap: break-word;
            width: 100%;
            color: #000000;
          }

          .file-holder {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-width: 200px;
            margin-bottom: 10px;
            border-radius: 10px;
            overflow: hidden;

            .visual-file {
              width: 100%;
              border-radius: 10px;
              cursor: pointer;
            }

            .document-file {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              max-width: 350px;
              padding: 5px;
              border-radius: 16px 16px 16px 0px;
              background: #f0eff5;
              color: #0f1828;
              cursor: pointer;

              .file-name {
                line-height: 16px;
                letter-spacing: 0em;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: 100%;
                padding: 1px 0;
                max-width: 80%;
                overflow: hidden;
              }

              i {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background: #f0eff5;
                color: #0f1828;
              }
            }
          }

          .message-footer {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.45);
          }
        }

        .bot-avatar {
          height: 28px;
          width: 28px;
        }

        .file-error {
          border: 5px solid #c01220 !important;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 5px #c8c8c8;
    }

    .invisible {
      opacity: 0;
    }
  }
}
</style>
