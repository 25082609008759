<template>
  <span
    :class="input ? 'input' : ''"
    :style="fromTaskSelect ? '' : 'margin-bottom:13px'"
    class="d-flex flex-row item"
  >
    <span
      v-if="opportunity"
      class="d-flex flex-row justify-content-between align-items-center w-100"
    >
      <span
        class="d-flex flex-row"
        style="align-items: flex-start"
        v-if="opportunity.name"
        @click="selection"
      >
        <avatar
          class="inner-avatar"
          :hasBorder="false"
          :hasStatus="false"
          avatarBackground="#109CF1"
          avatarColor="#FFFFFF"
          :avatarLetter="$service.getFirstLetter(opportunity.contact.name)"
        />

        <div class="opportunity-info">
          <span class="details" v-if="opportunity.name">
            <span class="name"> {{ opportunity.name }} </span>

            <span class="detail">&nbsp;#{{ opportunity.id }} &nbsp; </span>
          </span>

          <div v-if="fromTaskSelect" class="detail">
            {{ opportunity.workspace.name }}
          </div>
        </div>
      </span>

      <i class="icon-close" @click.prevent="clear" v-if="showClear" />
    </span>

    <span
      v-if="selectionEnabled && !opportunity"
      class="d-flex flex-row justify-content-between align-items-center w-100"
      @click="selection"
    >
      <span class="details">
        <span class="label">{{ selectionLabel || "Selecionar..." }}</span>
      </span>
      <i class="icon-add" />
    </span>
  </span>
</template>

<script>
import avatar from "@/views/components/Avatar.vue";
export default {
  components: { avatar },
  props: {
    opportunity: {
      type: Object,
      required: false,
    },
    showClear: {
      type: Boolean,
      default: false,
    },
    selectionLabel: {
      type: String,
      default: null,
    },
    selectionEnabled: {
      type: Boolean,
      default: true,
    },
    input: {
      type: Boolean,
      default: false,
    },
    fromTaskSelect: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clear() {
      this.$emit("clear");
    },
    selection() {
      if (this.selectionEnabled) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.item {
  display: flex;
  padding: 4px;
  justify-content: left;

  &.input {
    padding: 10px;
    border: 1px solid #d2d2d2;
  }

  .inner-avatar {
    width: 30px !important;
    height: 30px !important;
  }
}

.details {
  display: flex;
  .name {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    color: #4c5862;
  }

  .label {
    color: #333;
  }
}

.detail {
  text-align: left;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #999999;
}

.opportunity-item {
  display: flex;
  flex-direction: column;
}
</style>
