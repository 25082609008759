const initialState = () => ({
  isOpen: false,
});

const mutations = {
  TOGGLE_NAV_MENU(state) {
    state.isOpen = !state.isOpen;
  },

  CHANGE_NAV_MENU_CONDITION(state, condition) {
    state.isOpen = condition;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
};
