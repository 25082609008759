/**
 *
 * Opens a link redirecting to the opportunity's whatsapp.
 * @param {Object} informations - An object that has the seller name, opportunity, and workspace.
 * @return {void} Nothing.
 *
 */

const url = window.location.host;

const openWhatsappChannel = (informations) => {
  let phoneNumberCleaned =
    informations.opportunity.contact.phone_number.replace(/\D/g, "");

  if (phoneNumberCleaned.length <= 11) {
    phoneNumberCleaned = `55${phoneNumberCleaned}`;
  }

  const courseMessage = informations.opportunity.product
    ? `, vi que você escolheu o curso ${informations.opportunity.product.name}`
    : "";

  const message = `Olá ${informations.opportunity.name}, tudo bem? Me chamo ${informations.seller_name}, orientador(a) do ${informations.workspace}${courseMessage}. Que bom que chegou até nós. Vou continuar o seu atendimento por aqui.`;

  if (window.innerWidth < 600) {
    if (url == "crm.imlabs.com.br" || url == "crm.sandbox.imlabs.com.br") {
      window.open(
        `https://api.whatsapp.com/send?phone=${phoneNumberCleaned}&text=${message}`
      );
    } else {
      window.open(`https://api.whatsapp.com/send?phone=${phoneNumberCleaned}`);
    }

    return;
  }

  if (url == "crm.imlabs.com.br" || url == "crm.sandbox.imlabs.com.br") {
    window.open(
      `https://web.whatsapp.com/send?phone=${phoneNumberCleaned}&text=${message}`
    );
  } else {
    window.open(`https://web.whatsapp.com/send?phone=${phoneNumberCleaned}`);
  }
};

const openWhatsappChannelWithMessage = (informations) => {
  let phoneNumberCleaned =
    informations.opportunity.contact.phone_number.replace(/\D/g, "");

  if (phoneNumberCleaned.length <= 11) {
    phoneNumberCleaned = `55${phoneNumberCleaned}`;
  }

  if (window.innerWidth < 600) {
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumberCleaned}&text=${informations.message}`
    );

    return;
  }

  window.open(
    `https://web.whatsapp.com/send?phone=${phoneNumberCleaned}&text=${informations.message}`
  );
};

/**
 *
 * Opens a link redirecting to the opportunity's email.
 * @param {Object} email - A string with the opportunity's email.
 * @return {void} Nothing
 *
 */

const openEmailChannel = (email) => {
  window.open(`mailto:${email}`);
};

/**
 *
 * Opens a link redirecting to the opportunity's phone.
 * @param {Object} phone - A string with the opportunity's phone.
 * @return {void} Nothing
 *
 */

const openPhoneChannel = (phone) => {
  let phoneNumberCleaned = phone.replace(/\D/g, "");

  if (phoneNumberCleaned.length >= 12) {
    phoneNumberCleaned = phoneNumberCleaned.replace(/^55/i, "");
  }

  window.open(`tel:${phoneNumberCleaned}`);
};

export {
  openWhatsappChannel,
  openWhatsappChannelWithMessage,
  openEmailChannel,
  openPhoneChannel,
};
