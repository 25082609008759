<template>
  <div class="faq-component">
    <div class="faq-modal-body">
      <i
        class="icon-close-circle-outline close-button-style"
        @click="closeFaqModal"
      />

      <calendar-tutorial-videos v-if="currentModalPage == 'calendar'" />

      <omnichannel-tutorial-videos v-if="currentModalPage == 'omnichannel'" />

      <pipeline-tutorial-videos v-if="currentModalPage == 'pipeline'" />
    </div>
  </div>
</template>

<script>
import CalendarTutorialVideos from "./CalendarTutorialVideos.vue";
import OmnichannelTutorialVideos from "./OmnichannelTutorialVideos.vue";
import PipelineTutorialVideos from "./PipelineTutorialVideos.vue";

export default {
  components: {
    CalendarTutorialVideos,
    OmnichannelTutorialVideos,
    PipelineTutorialVideos,
  },

  data() {
    return {
      currentModalPage: "",
    };
  },

  mounted() {
    this.checkModalByPage();
  },

  computed: {},

  methods: {
    closeFaqModal() {
      this.$store.commit("faqVideos/CHANGE_FAQ_MODAL_CONDITION", false);
    },

    checkModalByPage() {
      this.currentModalPage = null;
      const route = this.$route.path;

      const possibleRoutes = {
        "/": "pipeline",
        "/dashboard": "dashboard",
        "/calendar": "calendar",
        "/omnichannel": "omnichannel",
        "/users": "users",
        "/contacts": "contacts",
      };

      this.currentModalPage = possibleRoutes[route];
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  background: #00000066;
  overflow: hidden;

  .faq-modal-body {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 84vh;
    width: 62vw;
    min-width: 550px;
    padding: 1.2rem 0;
    border-radius: 20px;
    background: white;
    border: solid 1px #9c9c9c;
    overflow-y: overlay;
    overflow-x: hidden;

    .close-button-style {
      position: sticky;
      font-size: 20px;
      top: 1rem;
      left: calc(100% - 2.5rem);
      width: 25px;
      color: #9c9c9c;
      cursor: pointer;
    }

    .close-button-style:hover {
      color: #cd364a;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      margin: 10px 0px;
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 10px #c8c8c8;
    }
  }

  @media (max-width: 800px) {
    .faq-modal-body {
      height: 100vh;
      width: 100vw;
      border-radius: 0px;
      min-width: auto;
    }
  }
}
</style>
