/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  contactIdFromOpportunityRedirect: null,
});

const getters = {
  getContactIdFromOpportunityRedirect(state) {
    return state.contactIdFromOpportunityRedirect;
  },
};

const mutations = {
  setContactIdFromOpportunityRedirect(state, contactId) {
    state.contactIdFromOpportunityRedirect = contactId;
  },
};

const actions = {
  list({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/contacts${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchOne({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/contacts/${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchLogs({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/contacts/${data.contactId}/logs${data.params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  saveContact({}, contact) {
    let workspaceId = localStorage.getItem("currentWorkspace");

    if (contact.workspace_id) {
      workspaceId = contact.workspace_id;
    }
    const postUrl = `/workspaces/${workspaceId}/contacts`;

    return new Promise((resolve, reject) => {
      instance()
        .put(`${postUrl}/${contact.id}`, contact)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
