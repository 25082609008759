import { $themeConfig } from '@themeConfig';

const initialState = () => ({
  isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
});

const mutations = {
  UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
    state.isVerticalMenuCollapsed = val;
  }
};

export default {
  namespaced: true,
  state: initialState(),
  mutations
};