<template>
  <div class="m-2">

    <!-- Header -->
    <div class="top-menu text-left">
      <i class="icon-close" @click="cancel" />
    </div>

    <div class="rating">
      <h2 class="pb-2">Como usar o Metodo de Qualificação</h2>

      <p class="pb-2">
        Não tem interesse
        <rate-bar :size="2" :rate="20" readonly="readonly" />
      </p>
      <p class="pb-2">
        Frio
        <rate-bar :size="2" :rate="40" readonly="readonly" />
      </p>
      <p class="pb-2">
        Morno
        <rate-bar :size="2" :rate="60" readonly="readonly" />
      <p class="pb-2">
        Quente
        <rate-bar :size="2" :rate="80" readonly="readonly" />
      </p>
      <p class="pb-2">
        Quero contratar
        <rate-bar :size="2" :rate="100" readonly="readonly" />
      </p>
    </div>
  </div>
</template>
<script>
import RateBar from './RateBar.vue';
export default {
  components: { RateBar },
  name: 'Help',
  props: {
    type: {
      type: String,
      default: '',
    }
  },

  methods: {
    cancel() {
      this.$emit("closed");
    },
  }
};
</script>
<style scoped lang="scss">
    h2 {
      max-width: 58%;
      text-align: center;
      margin: auto;
    }
    .rate-bar-container{
      width: 100%;
    }
</style>