<template>
  <div class="news-modal">
    <h1 style="text-align: center; margin-bottom: 15px">Novidades do CRM!</h1>

    <div>
      <iframe
        src="https://www.youtube.com/embed/NtwgFVcxtG0?rel=0&modestbranding=1&showinfo=0"
        title="TUTORIAL CRM ImLabs: Histórico e Gráficos"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </div>

    <h4>Distribuição de Leads</h4>

    <div>
      <iframe
        src="https://a.video.imlabs.com.br/videos/embed/9c1e6e9b-e93e-4ce9-83c5-db54b2f0a202"
        title="crm - distirbuicao de cards"
        frameborder="0"
        allowfullscreen=""
        sandbox="allow-same-origin allow-scripts allow-popups"
      />
    </div>

    <h4>Ordenação dos cards no pipe</h4>

    <div>
      <iframe
        src="https://www.youtube.com/embed/NH1oz_IzJSw?rel=0&modestbranding=1&showinfo=0"
        title="TUTORIAL CRM ImLabs: Classificação dos Cards"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </div>

    <div v-for="(video, index) in platformVideos" :key="index">
      <h4>{{ video.title }}</h4>

      <iframe
        :src="video.src"
        :title="video.title"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        frameborder="0"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      platformVideos: [],
    };
  },

  mounted() {
    this.getPlatformVideos();
  },

  methods: {
    getPlatformVideos() {
      this.$store.dispatch("whiteLabel/setPlatform");

      const platformData = this.$store.getters["whiteLabel/getPlatform"];

      this.platformVideos = platformData.faq_videos;
    },
  },
};
</script>

<style lang="scss" scoped>
.news-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 84vh;
  width: 100%;
  padding: 0 4.5rem;

  h4 {
    text-align: center;
    margin: 2rem 0 15px 0;
  }

  iframe {
    height: 450px;
    min-height: 450px;
    width: 55vw;
    min-width: 485px;
  }
}

@media (max-width: 800px) {
  .news-modal {
    min-height: 100vh;
    padding: 0 1.5rem;

    iframe {
      height: 450px;
      min-height: 450px;
      width: 100%;
      min-width: 0px;
    }
  }
}
</style>
