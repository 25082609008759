<template>
  <div class="vh-100 d-flex flex-column align-items-center">
    <h3 class="pt-2">Enviar E-mail</h3>
    <p class="text-center px-2 w-75 pb-1" v-if="opportunity.contact.email">
      Você será direcionado para o E-mail dessa oportunidade.
    </p>
    <p class="text-center px-2 w-75 pb-1" v-else>
      Atualmente essa oportunidade não possui E-mail integrado.
    </p>
    <b-button v-if="opportunity.contact.email" @click="openEmail(opportunity.contact.email)" class="email-chat-button">
      <div>
        <img src="@/assets/images/icons/phone-chat-icon.svg" />
      </div>
      Enviar E-mail
    </b-button>
    <img v-if="opportunity.contact.email" src="@/assets/images/email-illustration.svg" class="w-100 mx-auto" />
    <img v-else src="@/assets/images/email-illustration-gray.svg" class="w-100 mx-auto mt-2" />
  </div>
</template>
<script>
import { BButton } from "bootstrap-vue";
import { openEmailChannel } from "@/services/callService";

export default {
  components: {
    BButton,
  },
  props: {
    opportunity: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {
    openEmail(email) {
      openEmailChannel(email);
      this.$emit("email");
    },
  },
};
</script>
<style lang="scss" scoped>
.email-chat-button {
  background: #046af3 !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;

  div {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    margin-right: 7px;
    background: #fff;

    img {
      width: 14px;
    }
  }
}
</style>
