<template>
  <div id="list-product" class="d-flex flex-column h-100">
    <div class="d-flex justify-content-between pt-2 header">
      <h3 class="mt-1 mx-auto">Selecionar Produto</h3>
    </div>

    <div class="d-flex mt-1 px-2">
      <ul>
        <li class="search">
          <input-field
            v-model="search"
            placeholder="Buscar"
            @keyup="fetchProducts"
          />
          <i class="icon-search" />
        </li>
        <li v-for="item in items" :key="item.id" @click="select(item.id)">
          <product-item :product="item" />
        </li>
      </ul>
    </div>

    <div class="action-bar modal-bottom">
      <b-button @click="cancel"> Cancelar </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import InputField from "./InputField.vue";
import ProductItem from "./product-item.vue";

export default {
  components: { InputField, BButton, ProductItem },

  data() {
    return {
      search: "",
      items: [],
    };
  },

  created() {
    this.fetchProducts();
  },

  methods: {
    async fetchProducts() {
      let products = [];

      let search = [];
      if (this.search.length > 2) {
        search = [
          {
            query: "search",
            param: this.search,
          },
        ];
      }

      const params = [
        {
          query: "limit",
          param: 25,
        },
      ];

      await this.$store
        .dispatch(
          "opportunityStore/fetchProducts",
          this.$service.formatQueryParam([...params, ...search])
        )
        .then((p) => {
          products = p.data;
        });
      this.items = products;
    },

    cancel() {
      this.$emit("closed");
    },

    select(selectedId) {
      const selectedItem = this.items.filter(
        (item) => item.id === selectedId
      )[0];
      this.$emit("selected", selectedItem);
    },
  },
};
</script>

<style lang="scss" scoped>
#list-product {
  ul {
    border: 1px solid #e5e5e5;
    border-bottom: none;
    padding: 0;
    margin: 0;

    li.search {
      padding: 0px;
      margin: 0px;

      .input-field-container {
        padding: 0px;
        margin: 0px;
        :deep(input) {
          border: none;
        }
      }
      .icon-search {
        position: absolute;
        right: 40px;
        line-height: 45px;
      }
    }
    li {
      list-style: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 9px;
      display: flex;
      flex-direction: column;

      &:hover {
        font-weight: 500;
        box-shadow: 1px 1px 4px 1px #a5a5a53d;
      }
    }
  }
}
</style>
