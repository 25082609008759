<template>
  <div class="news-modal">
    <h1 style="text-align: center; margin: 15px 0">Omnichannel!</h1>

    <div>
      <iframe
        src="https://www.youtube.com/embed/9uu_q8JJbF4?rel=0&modestbranding=1&showinfo=0"
        title="TUTORIAL CRM ImLabs: Registro e Agenda de Tarefas"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss" scoped>
.news-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 84vh;
  width: 100%;
  padding: 0 4.5rem;

  h4 {
    text-align: center;
    margin: 2rem 0 15px 0;
  }

  iframe {
    height: 450px;
    min-height: 450px;
    width: 55vw;
    min-width: 485px;
  }
}

@media (max-width: 800px) {
  .news-modal {
    min-height: 100vh;
    padding: 0 1.5rem;

    iframe {
      height: 450px;
      min-height: 450px;
      width: 100%;
      min-width: 0px;
    }
  }
}
</style>
