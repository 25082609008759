const initialState = () => ({
  isOpen: false,
});

const mutations = {
  CHANGE_FAQ_MODAL_CONDITION(state, condition) {
    state.isOpen = condition;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
};
