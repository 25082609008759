<template>
  <div
    class="loading-component"
    :style="{
      background: data.gradient.primary
        ? `linear-gradient(to bottom, ${data.gradient.primary}, ${data.gradient.secondary})`
        : data.palette.secondary,
    }">
    <img :src="logo" class="loading-logo" />

    <div class="spinner-box">
      <b-spinner
        :variant="data.spinner_variant"
        style="width: 35px; height: 35px" />
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BSpinner,
  },

  props: {
    data: {
      required: true,
      default: "",
    },
  },

  data() {
    return {
      logo: require(`@/assets/images/logo/${this.data.logos.short_logo}`),
    };
  },
};
</script>

<style lang="scss" scoped>
.loading-component {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 100000;

  .loading-logo {
    position: absolute;
    top: 27%;
    left: 50%;
    transform: translate(-50%, 32%);
    width: 100px;
    height: auto;
  }

  .spinner-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, 55%);
  }
}
</style>
