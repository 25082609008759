import moment from "moment";
import Vue from "vue";
import VueCookie from "vue-cookie";

Vue.use(VueCookie);

export default {
  data() {
    return {
      MIXIN_stateOfTheOpportunityClosure: null,
    };
  },

  methods: {
    MIXIN_checkIfTheOpportunityHasATask(opportunity) {
      return opportunity.task ? true : false;
    },

    MIXIN_checkStepChangeForGainOrLost(opportunity, columns, isToCloseTheOpportunity) {
      if (isToCloseTheOpportunity) {
        return;
      }

      const stageType = this.MIXIN_checkType(opportunity.sales_stage_id);

      if (stageType == "Won") {
        this.MIXIN_showModal("bv-close_deal_won");
        return true;
      }

      if (stageType == "Lost") {
        this.MIXIN_showModal("bv-close_deal_lost");
        return true;
      }

      if ((stageType != "Won" || stageType != "Lost") && opportunity.state_type != "Open") {
        this.MIXIN_saveDeal("Open");
        return true;
      }

      return false;
    },

    MIXIN_checkType(stageId) {
      let stageType = null;

      this.columns.forEach((element) => {
        if (stageId == element.id) {
          stageType = element.type;
        }
      });

      return stageType;
    },

    MIXIN_verificationsForStepChange(isToCloseTheOpportunity = false) {
      const platformData = this.$store.getters["whiteLabel/getPlatform"];

      const hasATask = this.MIXIN_checkIfTheOpportunityHasATask(this.opportunity);

      if (!hasATask) {
        this.MIXIN_showModal("bv-task_form");
        this.MIXIN_showModal("bv-task_form_specific");
        return;
      }

      const isGainOrLost = this.MIXIN_checkStepChangeForGainOrLost(
        this.opportunity,
        this.columns,
        isToCloseTheOpportunity
      );

      if (isGainOrLost && !isToCloseTheOpportunity) {
        return;
      }

      if (isToCloseTheOpportunity) {
        this.$store
          .dispatch("opportunityStore/close", this.opportunity)
          .then(() => {
            this.$store
              .dispatch("opportunityStore/updateStep", this.opportunity)
              .then(() => {
                this.MIXIN_emitEvent({ name: "refresh", data: null });

                if (
                  this.MIXIN_checkType(this.opportunity.sales_stage_id) == "Won" &&
                  platformData.integrated_enroll == "imNic" &&
                  this.MIXIN_checkImNicToken()
                ) {
                  this.$store.dispatch("contract/setOpportunityForContract", this.opportunity);

                  this.MIXIN_showModal("bv-enrollment_form");
                }
              })
              .catch((error) => {
                if (error.response?.data?.status === 400) {
                  this.MIXIN_showModal("bv-data_correction");
                }
              });
          })
          .catch((error) => {
            if (error.response.data.status === 400) {
              this.MIXIN_showModal("bv-data_correction");
            }
          });

        return;
      }

      this.$store
        .dispatch("opportunityStore/updateStep", this.opportunity)
        .then(() => {
          this.MIXIN_emitEvent({ name: "refresh", data: null });
        })
        .catch((error) => {
          if (error.response.data.status === 400) {
            this.MIXIN_showModal("bv-data_correction");
          }
        });
    },

    MIXIN_checkImNicToken() {
      if (localStorage.getItem("imNicToken")) {
        return true;
      } else {
        return false;
      }
    },

    MIXIN_saveTaskForm() {
      this.opportunity.task = {};
      this.opportunity.task.count = 1;

      this.MIXIN_verificationsForStepChange();
    },

    MIXIN_saveDeal(state) {
      this.opportunity.closed_at = moment().format("YYYY-MM-DD HH:mm:ss");
      this.opportunity.state_type = state;
      this.MIXIN_stateOfTheOpportunityClosure = state;
      this.MIXIN_verificationsForStepChange(true);
    },

    MIXIN_saveDataCorrection() {
      this.MIXIN_verificationsForStepChange(this.MIXIN_stateOfTheOpportunityClosure ? true : false);
    },

    MIXIN_hideModal(modal) {
      this.$bvModal.hide(modal);
    },

    MIXIN_showModal(modal) {
      this.$bvModal.show(modal);
    },

    MIXIN_emitEvent(event) {
      this.$emit(event.name, event.data);
      this.MIXIN_hideModal("bv-data_correction");
      this.MIXIN_hideModal("bv-task_form");
      this.MIXIN_hideModal("bv-update_kanban_step");
    },
  },
};
