<template>
  <div id="dataCorrection" class="py-2 px-2" v-if="!hasRequest">
    <h4 style="margin: 0 auto; color: #000; font-weight: 600" class="mb-2">
      Confirmar informações
    </h4>

    <div class="title-info mb-3">
      <feather-icon icon="AlertCircleIcon" size="22" class="icon mr-1" />
      <span> Verifique todas as informações para a mudança de etapa. </span>
    </div>

    <validation-observer style="margin-bottom: 50px" ref="dataCorrectionValidation">
      <b-form @submit.prevent>
        <div class="mb-1">
          <contact-selector
            label="Contato"
            :contact="opportunity.contact"
            :cardId="opportunity.id"
          />
        </div>

        <validation-provider
          #default="{ errors }"
          immediate
          name="Nome Completo"
          rules="required"
          vid="name"
        >
          <small class="v-err text-danger">{{ errors[0] }}</small>

          <input-field
            id="name"
            placeholder="Nome Completo"
            trim
            class="mt-1"
            type="text"
            v-model="opportunity.contact.name"
            v-if="opportunity.contact"
          />
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          immediate
          name="Responsável"
          rules="required"
          vid="name"
        >
          <small class="v-err text-danger">{{ errors[0] }}</small>

          <div @click="openModal('bv-list_users')">
            <user-item
              label="Responsável"
              class="mt-1"
              variant="input"
              v-if="!hasRequest"
              v-model="opportunity.user"
            />
          </div>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          immediate
          name="celular"
          rules="required"
          vid="phone"
        >
          <small class="v-err text-danger">{{ errors[0] }}</small>
          <small class="text-danger" v-if="needDDD">O número precisa conter algum DDD</small>
          <vue-phone-number-input
            class="input-number"
            required
            size="lg"
            valid-color="#046af3"
            v-if="opportunity.contact"
            v-model="opportunity.contact.phone_number"
            :border-radius="0"
            :color="colorOfNumberInput"
            :default-country-code="opportunity.contact.country_code"
            :show-code-on-list="true"
            :translations="{
              countrySelectorLabel: 'Código do país',
              countrySelectorError: 'Escolha um país',
              phoneNumberLabel: 'Celular',
              example: 'Exemplo :',
            }"
            @update="setMobileNumberInformation($event)"
          />
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          immediate
          name="Origem"
          rules="required"
          vid="source_type"
        >
          <small class="v-err text-danger">{{ errors[0] }}</small>

          <v-select
            id="source_type"
            label="title"
            placeholder="Origem"
            style="z-index: 1001"
            v-model="opportunity.source_type"
            :options="source_types"
            :reduce="(option) => option.id"
          />
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          immediate
          name="Mídia"
          rules="required"
          vid="media"
        >
          <small class="v-err text-danger">{{ errors[0] }}</small>
          <v-select
            id="media"
            label="title"
            placeholder="Mídia"
            v-model="opportunity.media_id"
            :options="filtered_medias"
            :reduce="(option) => option.id"
          />
        </validation-provider>

        <small class="v-err text-danger" v-if="productError">{{ productError }}</small>

        <div
          class="step border justify-content-between align-items-center"
          v-if="isFrom === 'Won'"
          @click="openModal('bv-list_products')"
        >
          <product-item v-if="opportunity.product" :product="opportunity.product" />

          <span v-else class="d-flex">
            <span>Selecione um produto</span>
          </span>

          <i class="icon-close" @click.stop="unsetProduct" v-if="opportunity.product" />

          <i v-else class="icon-search" />
        </div>

        <b-form-group class="price-field mt-1" v-if="isFrom === 'Won'">
          <small class="v-err text-danger" v-if="priceError">{{ priceError }}</small>

          <money
            class="form-control price"
            id="price"
            placeholder="Venda"
            trim
            @blur.native="checkForMoneyError(opportunity.price)"
            v-model="opportunity.price"
          />

          <label class="floating">Valor da venda: </label>
        </b-form-group>

        <button type="submit" v-if="!hasSaveRequest" @click="save()" class="save-button-click">
          Salvar
        </button>

        <button class="save-button-click" v-else><b-spinner /> Aguarde...</button>
      </b-form>
    </validation-observer>

    <b-modal class="modal-dialog" id="bv-list_products" hide-footer hide-header>
      <div class="d-block text-center">
        <list-products @closed="hideModal('bv-list_products')" @selected="selectProduct($event)" />
      </div>
    </b-modal>

    <b-modal class="modal-dialog" id="bv-list_users" hide-footer hide-header>
      <div class="d-block text-center">
        <user-list @closed="hideModal('bv-list_users')" @selected="selectUser($event)" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BForm, BFormGroup, BSpinner } from "bootstrap-vue";
import ContactSelector from "./ContactSelector.vue";
import ListProducts from "./ListProducts.vue";
import ProductItem from "./product-item.vue";
import { Money } from "v-money";
import UserItem from "./UserItem.vue";
import InputField from "./InputField.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import vSelect from "vue-select";
import UserList from "./UserList.vue";
import { parsePhoneNumber } from "libphonenumber-js";
import { errorHandling } from "@/mixins";

export default {
  name: "DataCorrection",

  components: {
    ValidationObserver,
    BForm,
    ContactSelector,
    ListProducts,
    ProductItem,
    BFormGroup,
    Money,
    UserItem,
    ValidationProvider,
    InputField,
    VuePhoneNumberInput,
    vSelect,
    UserList,
    BSpinner,
  },

  props: {
    opportunityId: {
      type: [String, Number],
      default: null,
    },

    isFrom: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      hasRequest: false,
      hasSaveRequest: false,
      opportunity: {},
      colorOfNumberInput: "#d8d6de",
      needDDD: false,
      productError: null,
      priceError: null,
    };
  },

  mixins: [errorHandling],

  computed: {
    source_types() {
      return this.$store.getters["opportunityStore/getSourceTypes"];
    },

    allMedias() {
      return this.$store.getters["opportunityStore/getMedias"];
    },

    filtered_medias() {
      const filteredMedias = [];

      this.allMedias.forEach((media) => {
        filteredMedias.push({
          id: media.id,
          title: media.name,
        });
      });

      return filteredMedias;
    },
  },

  mounted() {
    this.hasRequest = true;

    this.fetchMedias();

    this.fetchOpportunity(this.opportunityId);
  },

  watch: {
    "opportunity.product": {
      handler: function (after, before) {
        if (after != before) {
          if (!after) {
            this.productError = "O campo Produto é obrigatório";
          } else {
            this.productError = null;
          }
        }
      },
      deep: true,
    },

    "opportunity.price": {
      handler: function (after, before) {
        if (after != before) {
          if (!after) {
            this.priceError = "O campo Valor da Venda é obrigatório";
          } else {
            this.priceError = null;
          }
        }
      },
      deep: true,
    },
  },

  methods: {
    openModal(modal) {
      this.$bvModal.show(modal);
    },

    hideModal(modal) {
      this.$bvModal.hide(modal);
    },

    checkForMoneyError(value) {
      if (value < 0) {
        this.opportunity.price = 0;
      } else if (value > 9999999.99) {
        this.opportunity.price = 9999999.99;
      }
    },

    selectProduct(product) {
      this.$set(this.opportunity, "product", product);
      this.opportunity.product_id = product.id;

      if (product.price) {
        this.opportunity.price = product.price;
      }

      this.hideModal("bv-list_products");
    },

    selectUser(user) {
      this.$set(this.opportunity, "user", user);
      this.opportunity.user_id = user.id;
      this.hideModal("bv-list_users");
    },

    fetchOpportunity(id) {
      this.$store
        .dispatch("opportunityStore/fetchOne", id)
        .then((response) => {
          this.opportunity = response.data;

          this.checkIfTheOpportunityHasProduct();
          this.setUpPhoneNumberFormatting();
          this.checkTheFieldsWithoutTheVeeValidate();
        })
        .finally(() => {
          this.hasRequest = false;
        });
    },

    fetchMedias() {
      if (this.allMedias.length !== 0) {
        return;
      }

      this.$store.dispatch("opportunityStore/fetchMedias");
    },

    setUpPhoneNumberFormatting() {
      if (this.opportunity.contact.country_code === null) {
        this.opportunity.contact.country_code = "BR";
      }

      this.opportunity.contact.phone_number = parsePhoneNumber(
        this.opportunity.contact.phone_number,
        this.opportunity.contact.country_code || "BR"
      ).formatNational();
    },

    setMobileNumberInformation(update) {
      this.opportunity.contact.country_code = update.countryCode;
      this.opportunity.contact.phone_number = update.formatNational;

      if (
        this.opportunity.contact.phone_number &&
        this.opportunity.contact.phone_number.length < 10 &&
        this.opportunity.contact.country_code === "BR"
      ) {
        this.colorOfNumberInput = "#ea5556";
        this.needDDD = true;
      } else {
        this.needDDD = false;
      }
    },

    checkIfTheOpportunityHasProduct() {
      if (!this.opportunity.price) {
        this.opportunity.price = 0;
      }

      if (this.opportunity.product_id) {
        this.$store
          .dispatch("opportunityStore/fetchProduct", this.opportunity.product_id)
          .then((product) => {
            this.opportunity.product = product.data;
          })
          .catch((error) => {
            this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);

            this.unsetProduct();
          });
      }
    },

    unsetProduct() {
      this.opportunity.product = null;
      this.opportunity.product_id = null;
    },

    checkTheFieldsWithoutTheVeeValidate() {
      if (!this.opportunity.product && this.isFrom === "Won") {
        this.productError = "O campo Produto é obrigatório";
        return false;
      }

      if (this.opportunity.price === 0 && this.isFrom === "Won") {
        this.priceError = "O campo Valor da Venda é obrigatório";
        return false;
      }

      if (this.needDDD) {
        return false;
      }

      return true;
    },

    save() {
      this.hasSaveRequest = true;
      const formRefs = this.$refs.dataCorrectionValidation;
      const areValid = this.checkTheFieldsWithoutTheVeeValidate();

      if (!areValid) {
        this.hasSaveRequest = false;
        return;
      }

      formRefs.validate().then((success) => {
        if (!success) {
          const el = document.querySelector(".v-err");
          el.scrollIntoView();
          this.hasSaveRequest = false;
          return;
        }

        this.$store.dispatch("opportunityStore/save", this.opportunity).then((response) => {
          this.opportunity = response.data;
          this.$emit("saved", this.opportunity);
        });
      });
    },
  },
};
</script>

<style lang="scss">
#dataCorrection {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .title-info {
    height: auto;
    width: 100%;
    background: #e1edfe;
    border: 1px solid #046af34d;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0.2rem;
    .icon {
      color: #046cf3;
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }
    span {
      color: #000;
      text-align: center;
    }
  }

  .step {
    text-align: center;
    padding: 12px 1em;
    display: flex;
    align-items: stretch;
    color: #192a3e;
    border-radius: 4px;
    cursor: pointer;
  }

  .price-field {
    .floating {
      float: left;
      margin-top: -26px;
      margin-left: 16px;
    }
  }

  .price {
    font-weight: 600;
    text-align: right;
    background: transparent;
  }

  .input-number {
    margin-bottom: 1rem;
    z-index: 1;
  }

  #source_type {
    transition: z-index 0.2s;
    z-index: 0;
  }

  .save-button-click {
    width: 100% !important;
    background: #fff !important;
    max-width: 500px;
    cursor: pointer;
    border: none !important;
    border-top: 1px solid rgba(0, 0, 0, 0.207) !important;
    color: #000 !important;
    font-weight: bold;
    position: fixed !important;
    z-index: 2000;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
  }
}
</style>
