<template>
  <div class="jitsi-holder">
    <b-button @click="addJitsiMeeting()" class="add-meeting" variant="primary">
      <i class="icon-video-chat-icon" />

      <div>
        {{
          hasMeeting
            ? "Videoconferência Adicionada"
            : "Adicionar videoconferência"
        }}
      </div>

      <i v-if="hasMeeting" class="icon-check" style="font-size: 10px" />
    </b-button>

    <div v-if="hasMeeting" class="meeting-icon-actions">
      <i
        @click="openWhatsJitsi()"
        class="icon-whatsapp-chat-icon"
        style="color: #2ed47a"
      />

      <i @click="openEmailJitsi()" class="icon-mail" style="color: #046af3" />

      <i
        @click="copyJitsiToClipboard()"
        class="icon-copy-outline"
        style="color: #1a73e8"
      />
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import moment from "moment";
import {
  openWhatsappChannelWithMessage,
  openEmailChannel,
} from "@/services/callService";

export default {
  components: {
    BButton,
  },

  props: {
    localOpportunity: {
      required: true,
      default: null,
    },

    taskTime: {
      required: true,
      default: null,
    },

    taskDate: {
      required: true,
      default: null,
    },

    task: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      hasMeeting: false,
      meetingMessage: "",
    };
  },

  computed: {
    platformData() {
      return this.$store.getters["whiteLabel/getPlatform"];
    },
  },

  methods: {
    useToastWarning(title, message, type) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 1500,
        variant: type,
        toaster: "b-toaster-top-left",
        solid: true,
      });
    },

    openWhatsJitsi() {
      const information = {
        opportunity: this.localOpportunity,
        message: this.meetingMessage,
      };

      openWhatsappChannelWithMessage(information);

      this.copyJitsiToClipboard();
    },

    openEmailJitsi() {
      const email = this.localOpportunity.contact.email;

      if (!email) {
        this.useToastWarning(
          "Oportunidade sem Email!",
          "Você precisa definir um email para realizar essa ação!",
          "warning"
        );

        return;
      }

      openEmailChannel(email);

      this.copyJitsiToClipboard();
    },

    async copyJitsiToClipboard() {
      const textToCopy = this.meetingMessage;

      try {
        await navigator.clipboard.writeText(textToCopy);
      } catch (err) {
        const tempInput = document.createElement("textarea");
        tempInput.value = textToCopy;
        document.body.appendChild(tempInput);

        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis

        try {
          document.execCommand("copy");
        } catch (err) {
          console.log(err);
        }

        document.body.removeChild(tempInput);
      }

      this.useToastWarning(
        "Copiado com sucesso!",
        "Dados da videoconferência copiado para a área de trasnferência",
        "success"
      );
    },

    hasAllMeetingRequirements() {
      if (this.hasMeeting) {
        return false;
      }

      if (!this.taskTime) {
        this.useToastWarning(
          "Defina um horário!",
          "Você precisa definir um horário para gerar uma videoconferência!",
          "warning"
        );

        return false;
      }

      if (!this.taskDate) {
        this.useToastWarning(
          "Defina uma data!",
          "Você precisa definir uma data para gerar uma videoconferência!",
          "warning"
        );

        return false;
      }

      if (!this.localOpportunity) {
        this.useToastWarning(
          "Defina uma Oportunidade!",
          "Você precisa definir uma oportunidade para gerar uma videoconferência!",
          "warning"
        );

        return false;
      }

      return true;
    },

    addJitsiMeeting() {
      if (this.hasAllMeetingRequirements()) {
        const platformName = this.platformData.short_name;
        const opportunityId = this.localOpportunity.id;

        const taskTime = this.taskTime;

        const taskDate = this.taskDate;

        const formattedHour = moment(taskTime, "HH:mm");
        const taskHoursPlusOne = formattedHour.add(1, "hour");
        const formattedTaskHoursPlusOne = taskHoursPlusOne.format("HH:mm");

        const formattedDate = moment(taskDate, "YYYY-MM-DD").format(
          "dddd, D [de] MMMM [de] YYYY"
        );

        const messageFirstLine = `${platformName} (${opportunityId})`;
        const messageSecondLine = `${formattedDate} · ${taskTime} até ${formattedTaskHoursPlusOne}`;
        const messageThirdLine = `Link da videochamada: https://meet.jit.si/${platformName}-${opportunityId}`;

        this.meetingMessage = `${messageFirstLine}\n${messageSecondLine}\n${messageThirdLine}`;

        this.$emit("jitsiObservation", this.meetingMessage);

        this.hasMeeting = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.jitsi-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  width: 100%;
  margin: 10px 0;

  .add-meeting,
  .meeting-icon-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    border-radius: 4px;
  }

  .add-meeting {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0em;
    width: 58%;
    padding: 10px;
    color: #ffffff;
  }

  .meeting-icon-actions {
    width: 40%;
    background: #ededed;

    i {
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>
