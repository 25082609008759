<template>
<span class="d-flex flex-column">
  <span class="name"> {{ product.name }} </span> 
  <span class="detail">Cód #{{ product.id }} - {{ $service.formatBrlAmountSymbol(product.price) }} </span>
</span>
</template>

<script>
  export default {
    props: ['product']
  };
</script>

<style scoped lang="scss">
  .name {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    color: #4C5862;
  }
  .detail {
    text-align: left;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #999999;
  }
</style>