/* eslint-disable no-empty-pattern */
import { instance } from "@/config/index.js";

const initialState = () => ({});

const actions = {
  getNotifications({}, params) {
    const url = `/notifications${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(url)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  readNotifications({}, id) {
    const url = `/notifications/${id}/read`;

    return new Promise((resolve, reject) => {
      instance()
        .get(url)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteOneNotification({}, id) {
    const url = `/notifications/${id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(url)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteAllGroupedNotifications({}, ids) {
    const url = `/notifications/batch-delete`;

    return new Promise((resolve, reject) => {
      instance()
        .post(url, ids)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  clearNotifications({}) {
    const url = `/notifications/clear`;

    return new Promise((resolve, reject) => {
      instance()
        .post(url)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  getNotificationConfig({}) {
    const url = `/user/settings`;

    return new Promise((resolve, reject) => {
      instance()
        .get(url)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  saveNotificationConfig({}, data) {
    const url = `/user/settings`;

    return new Promise((resolve, reject) => {
      instance()
        .post(url, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
};
