<template>
  <div class="white-veil">
    <div class="drop-box">
      <div class="arrows-line">
        <i class="icon-arrow-top" style="transform: rotate(315deg)" />

        <i class="icon-arrow-top" style="transform: rotate(45deg)" />
      </div>

      <img src="@/assets/images/icons/drop-file-icon.svg" alt="drop-file-box" />

      <div class="bigger-text">
        Arraste Imagen, vídeo, documento ou áudio aqui
      </div>

      <div class="smaller-text">
        O tamanho máximo para arquivos de mídia é de 16 MB.
      </div>

      <div class="arrows-line">
        <i class="icon-arrow-top" style="transform: rotate(225deg)" />

        <i class="icon-arrow-top" style="transform: rotate(135deg)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.white-veil {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background: #ffffffd0;

  .drop-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 245px;
    width: 380px;
    max-width: 80%;
    padding: 7px 12px;
    border-radius: 16px;
    border: solid 2px #50bded;
    background: #ffffff;
    color: #0a043c;

    .arrows-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      i {
        font-size: 16px;
        color: #50bded;
      }
    }

    img {
      height: 43px;
      width: 43px;
    }

    .bigger-text {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
      padding: 0 1rem;
    }

    .smaller-text {
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.2px;
      padding: 0 1.75rem;
    }
  }
}
</style>
