const initialState = () => ({
  currentOpportunityId: null,

  opportunityCreationInsideOpportunitySelect: false,

  opportunityCreationInsideTask: false,
});

const getters = {
  getCurrentOpportunityId(state) {
    return state.currentOpportunityId;
  },

  getOpportunityCreationInsideOpportunitySelectEvent(state) {
    return state.opportunityCreationInsideOpportunitySelect;
  },

  getOpportunityCreationInsideTaskEvent(state) {
    return state.opportunityCreationInsideTask;
  },
};

const actions = {
  // Setters

  setCurrentOpportunityId({ state }, id) {
    state.currentOpportunityId = id;
  },

  // Events

  disptachOpportunityCreationInsideOpportunitySelectEvent({ state }) {
    state.opportunityCreationInsideOpportunitySelect =
      !state.opportunityCreationInsideOpportunitySelect;
  },

  dispatchOpportunityCreationInsideTaskEvent({ state }) {
    state.opportunityCreationInsideTask = !state.opportunityCreationInsideTask;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};
