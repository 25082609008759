<template>
  <div class="history-holder">
    <div v-if="hasRequest" class="spinner-holder">
      <b-spinner />
    </div>

    <div v-else-if="logs.length" class="contact-history">
      <div class="log-header">
        <div class="date-header">Data</div>

        <div class="cards-header">Histórico de atividades</div>
      </div>

      <div v-for="(log, index) in logs" :key="index" class="logs-content-holder">
        <div class="logs-timeline">
          <div class="rounded-holder" :class="`bg-${actionColor(log.type)}`">
            <i :class="`icon-${actionIcon(log.type)}`" />
          </div>

          <div class="timeline-date">
            {{
              $service.dateFormat(
                log.created_at,
                (currentFormat = "YYYY-MM-DD HH:mm"),
                (toFormat = "DD/MM/YYYY")
              )
            }}
          </div>

          <div class="timeline-time">
            {{
              $service.dateFormat(
                log.created_at,
                (currentFormat = "YYYY-MM-DD HH:mm"),
                (toFormat = "HH[h]mm")
              )
            }}
          </div>

          <i class="icon-circle" :class="`bg-${actionColor(log.type)} ${actionColor(log.type)}`" />

          <div class="timeline-line" />
        </div>

        <div class="cards-holder">
          <opportunity-closed-won
            v-if="log.type === 'OpportunityClosedWon'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-closed-lost
            v-else-if="log.type === 'OpportunityClosedLost'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-sales-stage-updated
            v-else-if="log.type === 'OpportunitySalesStageUpdated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-price-updated
            v-else-if="log.type === 'OpportunityPriceUpdated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-price-created
            v-else-if="log.type === 'OpportunityPriceCreated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-probability-updated
            v-else-if="log.type === 'OpportunityProbabilityUpdated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-description-updated
            v-else-if="log.type === 'OpportunityDescriptionUpdated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-product-created
            v-else-if="log.type === 'OpportunityProductCreated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-product-updated
            v-else-if="log.type === 'OpportunityProductUpdated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-created
            v-else-if="log.type === 'OpportunityCreated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-user-updated
            v-else-if="log.type === 'OpportunityUserUpdated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-reopened
            v-else-if="log.type === 'OpportunityReopened'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-recreated
            v-else-if="log.type === 'OpportunityRecreated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-tma
            v-else-if="log.type === 'OpportunityTma'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <task-registry
            v-else-if="log.type === 'TaskRegistry'"
            @openTask="openTask(log.data.id)"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <task-registry-deleted
            v-else-if="log.type === 'TaskRegistryDeleted'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <task-created
            v-else-if="log.type === 'TaskCreated'"
            @openTask="openTask(log.data.id)"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <task-closed
            v-else-if="log.type === 'TaskClosed'"
            @openTask="openTask(log.data.id)"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <task-reopened
            v-else-if="log.type === 'TaskReopened'"
            @openTask="openTask(log.data.id)"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <task-deleted
            v-else-if="log.type === 'TaskDeleted'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
            :description="log.data.description"
          />

          <opportunity-transferred
            v-else-if="log.type === 'OpportunityTransferred'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <contract-created
            v-else-if="log.type === 'ContractCreated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <contract-deleted
            v-else-if="log.type === 'ContractDeleted'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <contract-payment-status-updated
            v-else-if="log.type === 'ContractPaymentStatusUpdated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <contract-status-updated
            v-else-if="log.type === 'ContractStatusUpdated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <contract-updated
            v-else-if="log.type === 'ContractUpdated'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-room-status-closed
            v-else-if="log.type === 'OpportunityRoomStatusClosed'"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-room-status-reopened
            v-else-if="log.type === 'OpportunityRoomStatusReopened'"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-call-success
            v-else-if="log.type === 'OpportunityCallSuccess'"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-call-attempt
            v-else-if="log.type === 'OpportunityCallAttempt'"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <batch-messages-reply
            v-else-if="log.type === 'BatchMessageReply'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />

          <opportunity-created-from-import
            v-else-if="log.type === 'OpportunityCreatedFromImport'"
            :title="getActionDescription(log.type)"
            :log="log"
            :fromUserModal="fromUserModal"
            :fromContactModal="fromContactModal"
          />
        </div>
      </div>
    </div>

    <div v-else class="no-logs text-muted">Nenhum histórico encontrado!</div>

    <b-modal id="bv-history-task" class="modal-dialog" hide-footer hide-header>
      <task-form
        @closed="refresh()"
        @saved="refresh()"
        @deleted="refresh()"
        :task="currentTask"
        :isUpdate="true"
      />
    </b-modal>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import tmaServices from "../services/tmaServices";
import BatchMessagesReply from "@/views/components/History/BatchMessageReply.vue";
import OpportunityClosedWon from "./components/History/OpportunityClosedWon.vue";
import OpportunityClosedLost from "./components/History/OpportunityClosedLost.vue";
import OpportunitySalesStageUpdated from "./components/History/OpportunitySalesStageUpdated.vue";
import OpportunityPriceCreated from "./components/History/OpportunityPriceCreated.vue";
import OpportunityPriceUpdated from "./components/History/OpportunityPriceUpdated.vue";
import OpportunityProbabilityUpdated from "./components/History/OpportunityProbabilityUpdated.vue";
import OpportunityDescriptionUpdated from "./components/History/OpportunityDescriptionUpdated.vue";
import OpportunityProductCreated from "./components/History/OpportunityProductCreated.vue";
import OpportunityProductUpdated from "./components/History/OpportunityProductUpdated.vue";
import OpportunityCreated from "./components/History/OpportunityCreated.vue";
import OpportunityUserUpdated from "./components/History/OpportunityUserUpdated.vue";
import OpportunityReopened from "./components/History/OpportunityReopened.vue";
import OpportunityRecreated from "./components/History/OpportunityRecreated.vue";
import OpportunityTma from "./components/History/OpportunityTma.vue";
import TaskRegistry from "./components/History/TaskRegistry.vue";
import TaskRegistryDeleted from "./components/History/TaskRegistryDeleted.vue";
import TaskCreated from "./components/History/TaskCreated.vue";
import TaskClosed from "./components/History/TaskClosed.vue";
import TaskReopened from "./components/History/TaskReopened.vue";
import TaskDeleted from "./components/History/TaskDeleted.vue";
import OpportunityTransferred from "./components/History/OpportunityTransferred.vue";
import ContractCreated from "./components/History/ContractCreated.vue";
import ContractDeleted from "./components/History/ContractDeleted.vue";
import ContractPaymentStatusUpdated from "./components/History/ContractPaymentStatusUpdated.vue";
import ContractStatusUpdated from "./components/History/ContractStatusUpdated.vue";
import ContractUpdated from "./components/History/ContractUpdated.vue";
import OpportunityRoomStatusClosed from "./components/History/OpportunityRoomStatusClosed.vue";
import OpportunityRoomStatusReopened from "./components/History/OpportunityRoomStatusReopened.vue";
import OpportunityCallSuccess from "./components/History/OpportunityCallSuccess.vue";
import OpportunityCallAttempt from "./components/History/OpportunityCallAttempt.vue";
import TaskForm from "@/views/forms/TaskForm.vue";
import OpportunityCreatedFromImport from "./components/History/OpportunityCreatedFromImport.vue";

export default {
  components: {
    BSpinner,
    BatchMessagesReply,
    OpportunityClosedWon,
    OpportunityClosedLost,
    OpportunitySalesStageUpdated,
    OpportunityPriceCreated,
    OpportunityPriceUpdated,
    OpportunityProbabilityUpdated,
    OpportunityDescriptionUpdated,
    OpportunityProductCreated,
    OpportunityProductUpdated,
    OpportunityCreated,
    OpportunityUserUpdated,
    OpportunityReopened,
    OpportunityRecreated,
    OpportunityTma,
    TaskRegistry,
    TaskRegistryDeleted,
    TaskCreated,
    TaskClosed,
    TaskReopened,
    TaskDeleted,
    OpportunityTransferred,
    ContractCreated,
    ContractDeleted,
    ContractPaymentStatusUpdated,
    ContractStatusUpdated,
    ContractUpdated,
    OpportunityRoomStatusClosed,
    OpportunityRoomStatusReopened,
    OpportunityCallSuccess,
    OpportunityCallAttempt,
    TaskForm,
    OpportunityCreatedFromImport,
  },

  props: {
    contact: {
      default: null,
      required: true,
    },

    externalFetch: {
      type: Boolean,
      default: false,
      required: false,
    },

    fromUserModal: {
      type: Boolean,
      default: false,
      required: false,
    },

    fromContactModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  created() {
    if (this.externalFetch) {
      this.logs = this.contact;
      this.takeTmaLog(this.contact);
      this.hasRequest = false;
    } else {
      this.fetch();
    }
  },

  data() {
    return {
      logs: [],

      actions: [
        {
          id: "OpportunityClosedWon",
          title: "Oportunidade Ganha",
          icon: "thumbs-up",
          color: "green",
        },
        {
          id: "OpportunityClosedLost",
          title: "Oportunidade Perdida",
          icon: "thumbs-down",
          color: "red",
        },
        {
          id: "OpportunitySalesStageUpdated",
          title: "Etapa do Funil Alterada",
          icon: "align",
          color: "purple",
        },
        {
          id: "OpportunityPriceUpdated",
          title: "Preço Alterado",
          icon: "charges",
          color: "light-blue",
        },
        {
          id: "OpportunityPriceCreated",
          title: "Preço Adicionado",
          icon: "charges",
          color: "blue",
        },
        {
          id: "OpportunityProbabilityUpdated",
          title: "Qualificação Alterada",
          icon: "star",
          color: "yellow",
        },
        {
          id: "OpportunityDescriptionUpdated",
          title: "Descrição Alterada",
          icon: "mail",
          color: "yellow",
        },
        {
          id: "OpportunityProductCreated",
          title: "Produto Adicionado",
          icon: "sale",
          color: "light-blue",
        },
        {
          id: "OpportunityProductUpdated",
          title: "Produto Atualizado",
          icon: "sale",
          color: "yellow",
        },
        {
          id: "OpportunityCreated",
          title: "Oportunidade Criada",
          icon: "check-circle-outline",
          color: "green",
        },
        {
          id: "OpportunityUserUpdated",
          title: "Transferência de Responsável",
          icon: "client",
          color: "purple",
        },
        {
          id: "OpportunityReopened",
          title: "Oportunidade Reaberta",
          icon: "transfer-error",
          color: "green",
        },
        {
          id: "OpportunityRecreated",
          title: "Oportunidade Recriada",
          icon: "transfer-error",
          color: "purple",
        },
        {
          id: "OpportunityTma",
          title: "Tempo de atendimento",
          icon: "task-clock",
          color: "red",
        },
        {
          id: "TaskRegistry",
          title: "Tarefa registrada",
          icon: "check",
          color: "green",
        },
        {
          id: "TaskRegistryDeleted",
          title: "Registro de Tarefa Deletado",
          icon: "close",
          color: "red",
        },
        {
          id: "TaskCreated",
          title: "Tarefa agendada",
          icon: "task-clock",
          color: "yellow",
        },
        {
          id: "TaskClosed",
          title: "Tarefa Finalizada",
          icon: "star",
          color: "yellow",
        },
        {
          id: "TaskReopened",
          title: "Tarefa Reaberta",
          icon: "refresh",
          color: "blue",
        },
        {
          id: "TaskDeleted",
          title: "Tarefa Deletada",
          icon: "close-circle",
          color: "red",
        },
        {
          id: "OpportunityTransferred",
          title: "Oportunidade transferida",
          icon: "check",
          color: "blue",
        },
        {
          id: "ContractCreated",
          title: "Contrato Criado",
          icon: "upload-file",
          color: "blue",
        },
        {
          id: "ContractDeleted",
          title: "Contrato Deletado",
          icon: "upload-file",
          color: "red",
        },
        {
          id: "ContractPaymentStatusUpdated",
          title: "Estatus de Pagamento Alterado",
          icon: "upload-file",
          color: "light-blue",
        },
        {
          id: "ContractStatusUpdated",
          title: "Estatus do Contrato Atualizado",
          icon: "pencil-line",
          color: "light-blue",
        },
        {
          id: "ContractUpdated",
          title: "Contrato Atualizado",
          icon: "pencil-line",
          color: "yellow",
        },
        {
          id: "OpportunityRoomStatusClosed",
          icon: "check",
          color: "blue",
        },
        {
          id: "OpportunityRoomStatusReopened",
          icon: "transfer-error",
          color: "green",
        },
        {
          id: "OpportunityCallSuccess",
          icon: "phone-chat-icon",
          color: "green",
        },
        {
          id: "OpportunityCallAttempt",
          icon: "phone-chat-icon",
          color: "red",
        },
        {
          id: "BatchMessageReply",
          title: "Disparo em Massa Efetuado",
          icon: "chat-ballon",
          color: "blue",
        },
        {
          id: "OpportunityCreatedFromImport",
          title: "Oportunidade Importada",
          icon: "upload-file",
          color: "blue",
        },
      ],

      hasRequest: true,

      totalCount: 0,
      offset: 0,

      tma: {
        tmaValue: 0,
        tmaCreatedAt: 0,
        tmaCreatedAtHours: 0,
        tmaDate: 0,
        tmaDateHours: 0,
        steps: null,
      },

      hasTaskRequest: false,
      currentTask: {},
    };
  },

  methods: {
    refresh() {
      this.$emit("refresh");
    },

    openTask(id) {
      if (this.hasTaskRequest) {
        return;
      }

      this.hasTaskRequest = true;

      this.$store
        .dispatch("calendarStore/fetchOneTask", id)
        .then((response) => {
          this.currentTask = response.data;

          this.$store
            .dispatch("opportunityStore/fetchOne", this.currentTask.parent_id)
            .then((response) => {
              this.currentTask.opportunity = response.data;

              this.$bvModal.show("bv-history-task");
            })
            .finally(() => {
              this.hasTaskRequest = false;
            });
        })
        .catch(() => {
          this.hasTaskRequest = false;
        });
    },

    fetch() {
      let queryParams = [
        {
          query: "limit",
          param: 100,
        },
        {
          query: "offset",
          param: this.offset,
        },
      ];

      this.$store
        .dispatch("contactStore/fetchLogs", {
          contactId: this.contact.id,
          params: this.$service.formatQueryParam(queryParams),
        })
        .then((res) => {
          this.logs = res.data;
          this.takeTmaLog(this.logs);
          this.hasRequest = false;
        });
    },

    takeTmaLog(logs) {
      let created;
      for (let i = 0; i < logs.length; i++) {
        if (logs[i].type == "OpportunityTma") {
          this.tma.tmaValue = logs[i].data.tma;
          created = this.logs[i].data.opportunity_created_at;

          let tmaDate = new Date((this.tma.tmaValue + created) * 1000);
          this.tma.tmaDate = tmaDate.toLocaleDateString("PT-BR");
          this.tma.tmaDateHours = tmaDate.toLocaleTimeString("PT-BR");

          created = new Date(created * 1000);
          this.tma.tmaCreatedAt = created.toLocaleDateString("PT-BR");
          this.tma.tmaCreatedAtHours = created.toLocaleTimeString("PT-BR");
          this.tma.tmaValue = tmaServices.formatter(this.tma.tmaValue);

          logs[i].data.tmaFormatted = this.tma;

          this.tma = {};
        }
      }
    },

    actionIcon(action) {
      const current = this.actions.find((a) => a.id == action);

      if (!current) return;

      return current.icon;
    },

    actionColor(action) {
      const current = this.actions.find((a) => a.id == action);

      if (!current) return;

      return current.color;
    },

    getActionDescription(id) {
      const action = this.actions.find((action) => action.id === id);
      if (action) {
        return action.title;
      }
      return id;
    },
  },
};
</script>

<style lang="scss" scoped>
.history-holder {
  height: 100%;
  width: 100%;

  .spinner-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
  }

  .contact-history {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 10px;

    .log-header {
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      gap: 10px;
      margin-bottom: 20px;
      color: #4c5862;

      .date-header {
        min-width: 90px;
      }

      .cards-header {
        width: 100%;
      }
    }

    .logs-content-holder {
      display: grid;
      grid-template-columns: 90px 1fr;
      align-items: flex-start;
      width: 100%;
      overflow: hidden;

      .logs-timeline {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 90px;

        .rounded-holder {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          min-width: 40px;
          min-height: 40px;
          border-radius: 6px;
          color: #ffffff;
        }

        .timeline-date {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #212525;
        }

        .timeline-time {
          font-weight: 600;
          color: #999999;
        }

        .icon-circle {
          border-radius: 100%;
          border: 3px solid #c4c4c4;
        }

        .timeline-line {
          height: 100%;
          width: 1px;
          border-right: 2px solid #c4c4c4;
        }

        .bg-purple {
          background-color: #9160de;
        }
        .bg-red {
          background-color: #ff2c55;
        }
        .bg-light-blue {
          background-color: #38a7ff;
        }
        .bg-blue {
          background-color: #046af3;
        }
        .bg-green {
          background-color: #2ed47a;
        }
        .bg-yellow {
          background-color: #f8aa00;
        }
        .bg-orange {
          color: #f8aa00;
        }

        .purple {
          color: #9160de;
        }
        .red {
          color: #ff2c55;
        }
        .light-blue {
          color: #38a7ff;
        }
        .blue {
          color: #046af3;
        }
        .green {
          color: #2ed47a;
        }
        .yellow {
          color: #f8aa00;
        }
        .orange {
          color: #f8aa00;
        }
      }

      .cards-holder {
        max-width: 100%;
        overflow: hidden;

        .card {
          word-break: break-word !important;
          border-radius: 16px;
          border: 1px solid #d2d2d2;
          overflow: hidden;
        }
      }
    }
  }

  .no-logs {
    padding: 20px 0;
    text-align: center;
  }
}
</style>
