<template>
  <div class="rate-bar-container">
    <i
      v-for="(item, index) in starNumber"
      @click="changeRating(index)"
      :class="{ active: index + 1 <= starValue / (100 / starNumber) }"
      :key="index"
      :style="cssProps"
      class="icon-star"
    />
  </div>
</template>

<script>
export default {
  props: {
    starNumber: {
      type: Number,
      default: 5,
      require: true,
    },

    size: {
      type: Number,
      default: 1,
    },

    rate: {
      type: Number,
      default: null,
      require: true,
    },

    isEdit: {
      type: Boolean,
      default: false,
    },

    static: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      starValue: this.rate,
    };
  },

  computed: {
    cssProps() {
      return {
        "--font-size": `${this.size * 1}em`,
      };
    },
  },

  methods: {
    changeRating(rating) {
      if (this.static) {
        return;
      }

      this.starValue = (rating + 1) * 20;
      this.$emit("update-rating", this.starValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.rate-bar-container {
  i {
    margin-right: 5px;
    color: #aab5c6;
    font-size: var(--font-size);

    &.active {
      color: #f8df00;
    }
  }
}
</style>
