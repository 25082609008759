/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  log_type_dictionary: [
    { id: "OpportunityCreated", title: "Oportunidade Criada" },
    { id: "OpportunitySalesStageUpdated", title: "Etapa do Funil Alterada" },
    { id: "OpportunityUserUpdated", title: "Transferência de Responsável" },
    { id: "OpportunityDescriptionUpdated", title: "Descrição Alterada" },
    { id: "OpportunityProbabilityUpdated", title: "Qualificação Alterada" },
    { id: "OpportunityPriceCreated", title: "Preço Adicionado" },
    { id: "OpportunityPriceUpdated", title: "Preço Alterado" },
    { id: "OpportunityProductCreated", title: "Produto Adicionado" },
    { id: "OpportunityProductUpdated", title: "Produto Atualizado" },
    { id: "OpportunityClosedWon", title: "Oportunidade Ganha" },
    { id: "OpportunityClosedLost", title: "Oportunidade Perdida" },
    { id: "OpportunityReopened", title: "Oportunidade Reaberta" },
    { id: "OpportunityRecreated", title: "Oportunidade Recriada" },
    { id: "OpportunityTma", title: "Tempo de Atendimento" },
    { id: "TaskCreated", title: "Tarefa Agendada" },
    { id: "TaskClosed", title: "Tarefa Finalizada" },
    { id: "TaskDeleted", title: "Tarefa Deletada" },
    { id: "TaskReopened", title: "Tarefa Reaberta" },
    { id: "TaskRegistry", title: "Tarefa Registrada" },
    { id: "TaskRegistryDeleted", title: "Registro de Tarefa Deletado" },
    { id: "OpportunityTransferred", title: "Oportunidade Transferida" },
    { id: "OpportunityRoomStatusClosed", title: "Sala de Chat Fechada" },
    { id: "TaskRescheduled", title: "Tarefa Reagendada" },
    { id: "ContractCreated", title: "Contrato Criado" },
    { id: "ContractUpdated", title: "Contrato Atualizado" },
    { id: "ContractDeleted", title: "Contrato Deletado" },
    {
      id: "ContractPaymentStatusUpdated",
      title: "Status do Contrato Atualizado",
    },
    { id: "OpportunityCallAttempt", title: "Tentativa de Chamada " },
    { id: "OpportunityCallSuccess", title: "Chamada Bem-Sucedida" },
    { id: "OpportunityRoomStatusReopened", title: "Sala reaberta" },
    { id: "OpportunityCreatedFromImport", title: "Oportunidade Importada" },
    { id: "BatchMessageReply", title: "Disparo em Massa Efetuado" },
  ],

  roles_dictionary: [
    // Base roles
    { id: "salesRep", title: "Atendente" },
    { id: "manager", title: "Gerente" },
    { id: "admin", title: "Administrador" },

    // Roles SGF
    { id: "consultorFranquia", title: "Consultor de Franquia" },
    { id: "gestorFranquia", title: "Gestor de Franquia" },
    { id: "diretorFranquia", title: "Diretor de Franquia" },
    { id: "gerenteComercial", title: "Gerente Comercial" },
    { id: "consultorComercial", title: "Consultor Comercial" },
    { id: "franqueado", title: "Franqueado" },
    { id: "auxiliarTi", title: "Auxiliar de TI" },
    { id: "ceo", title: "CEO" },
    { id: "master", title: "Master" },
    { id: "gerador", title: "Gerador" },

    // External roles with specific permissions
    { id: "uraApi", title: "URA Api", isApi: true },
    { id: "imBotApi", title: "Im Bot Api", isApi: true },
    { id: "voipApi", title: "Voip Api", isApi: true },
    { id: "imLandingPageApi", title: "Im Landing Page Api", isApi: true },
    { id: "opportunityApi", title: "Opportunity Api", isApi: true },
  ],

  default_date_by_role: {
    admin: "3-months",
    manager: "3-months",
    franqueado: "3-months",
    gestorFranquia: "3-months",
    diretorFranquia: "3-months",
    gerenteComercial: "3-months",
    auxiliarTi: "3-months",
    salesRep: "1-month",
    gerador: "1-month",
    consultorFranquia: "24-hours",
    consultorComercial: "24-hours",
    ceo: "24-hours",
    master: "24-hours",
  },

  operational_status_dictionary: [
    { id: "Working", title: "Trabalhando" },
    { id: "Vacation", title: "Férias" },
    { id: "CompanyLeave", title: "Afastado" },
    { id: "SickLeave", title: "Enfermo" },
    { id: "License", title: "Licença" },
    { id: "Training", title: "Treinamento" },
    { id: "IMEvent", title: "Evento Interno" },
    { id: "ExternalEvent", title: "Evento Externo" },
    { id: "Other", title: "Outro" },
  ],

  opportunity_for_history: null,
});

const getters = {
  getLogTypes(state) {
    return state.log_type_dictionary;
  },

  getOpportunityForHistory(state) {
    return state.opportunity_for_history;
  },

  getRoles(state) {
    return state.roles_dictionary;
  },

  getOperationalStatus(state) {
    return state.operational_status_dictionary;
  },

  getDefaultDateByRole(state) {
    return state.default_date_by_role;
  },
};

const mutations = {};

const actions = {
  list({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/users${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getUser({}, userId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/users/${userId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getUserFromOtherWorkspace({}, info) {
    const getUrl = `/workspaces/${info.workspaceId}/users/${info.userId}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(getUrl)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  updateUser({}, userData) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/users/${userData.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, userData)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getWorkspaces({}, { userId, params }) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/users/${userId}/workspaces${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  listHierarchically({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/users-in-hierarchy${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getUserHistory({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/logs${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  getUserLogHistory({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/user-logs${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  inviteUser({}, invitationData) {
    const postUrl = `/workspaces/${invitationData.workspaceId}/user-invitations`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, invitationData)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  validateUserInvite({}, user_invitation_id) {
    const postUrl = `user-invitations/${user_invitation_id}/validate`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  checkForUserEmail({}, info) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `workspaces/${workspaceId}/users/find-one-by-username`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, info)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  removeUserFromWorkspace({}, data) {
    const deleteUrl = `workspaces/${data.workspaceId}/users/${data.userId}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  setOpportunityForHistory({ state }, id) {
    let opportunity;

    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/opportunities/${id}`)
        .then((response) => {
          if (response.data.length === 0) {
            return;
          }

          opportunity = response.data;

          state.opportunity_for_history = opportunity;
        })
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
