<template>
  <div class="contact-general animate__animated animate__fadeIn">
    <div
      v-for="(contactAction, index) in contactActions"
      @click="contactAction.method()"
      :key="index"
      :title="contactAction.title"
      :style="`border: 1px solid ${contactAction.color} !important; color: ${contactAction.color}`"
      class="contact-button"
      v-b-tooltip.hover
    >
      <i :class="`icon-${contactAction.icon}`" />
    </div>

    <b-modal
      class="modal-dialog"
      id="bv-quick-task_form"
      hide-footer
      hide-header
    >
      <div class="d-block text-center" v-if="task.opportunity">
        <task-form
          createdFrom="Opportunity"
          :opportunity="task.opportunity"
          :parentId="task.opportunity.id"
          :fromTaskLocation="fromLocation"
          :fromTaskCall="fromCall"
          :fromTaskEmail="fromEmail"
          @closed="closeModal('bv-quick-task_form')"
          @saved="savedTaskForm()"
        />
      </div>
    </b-modal>

    <b-modal
      id="bv-update_opportunity_modal_historic"
      class="modal-dialog"
      hide-footer
      hide-header
    >
      <div class="d-block text-center" v-if="task.opportunity">
        <opportunity-and-contacts
          :opportunity="task.opportunity"
          :isOnlyHistoric="true"
          @saved="savedTaskForm()"
          @closed="closeModal('bv-update_opportunity_modal_historic')"
          @refresh="$emit('refreshColumns')"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import { openEmailChannel, openPhoneChannel } from "@/services/callService";

export default {
  components: {
    OpportunityAndContacts: () => import("../OpportunityAndContacts.vue"),
    TaskForm: () => import("../TaskForm"),
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {
    task: {
      type: Object,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      fromLocation: false,
      fromCall: false,
      fromEmail: false,

      contactActions: [
        {
          id: 1,
          title: "Fechar",
          icon: "close",
          color: "",
          method: () => {
            this.closeTaskModal();
          },
        },
        {
          id: 2,
          title: "Telefone",
          icon: "phone-chat-icon",
          color: "#046af3",
          method: () => {
            this.openCall();
          },
        },
        {
          id: 3,
          title: "E-Mail",
          icon: "email-svgrepo-com",
          color: "#046af3",
          method: () => {
            this.openEmail();
          },
        },
        {
          id: 4,
          title: "Registro de visita",
          icon: "client",
          color: "#046af3",
          method: () => {
            this.openLocation();
          },
        },
        {
          id: 5,
          title: "Histórico",
          icon: "task-clock",
          color: "#046af3",
          method: () => {
            this.openHistory();
          },
        },
      ],
    };
  },

  methods: {
    openModal(modal) {
      this.$bvModal.show(modal);
    },

    closeModal(modal) {
      this.$bvModal.hide(modal);
    },

    closeTaskModal() {
      this.$emit("close");
    },

    cleanValues() {
      this.fromLocation = false;
      this.fromCall = false;
      this.fromEmail = false;
    },

    openCall() {
      this.cleanValues();

      openPhoneChannel(this.task.opportunity.contact.phone_number);
      this.fromCall = true;
      this.openModal("bv-quick-task_form");
    },

    openEmail() {
      this.cleanValues();

      if (!this.task.opportunity.contact.email) {
        this.$bvToast.toast("Contato da tarefa não possui email cadastrado!", {
          title: `Sem Email!`,
          autoHideDelay: 1500,
          variant: "warning",
          toaster: "b-toaster-top-left",
          solid: true,
        });

        return;
      }

      openEmailChannel(this.task.opportunity.contact.email);
      this.fromEmail = true;
      this.openModal("bv-quick-task_form");
    },

    openLocation() {
      this.cleanValues();

      this.fromLocation = true;
      this.openModal("bv-quick-task_form");
    },

    openHistory() {
      this.openModal("bv-update_opportunity_modal_historic");
    },

    savedTaskForm() {
      this.$emit("finished");
      this.closeModal("bv-quick-task_form");
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-general {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #e4e4e4;

  .contact-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    min-width: 44px;
    border-radius: 50%;
    background: #f5f6f8;
    cursor: pointer;

    &:after {
      background: transparent !important;
    }

    i {
      font-size: 20px;
    }
  }
}
</style>
