<template>
  <div>
    <i class="icon-close" @click="$emit('closed')" />

    <iframe
      sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin"
      class="iframe"
      :src="iframeLink"
      frameborder="0"
      ref="iframe"
    />
  </div>
</template>

<script>
export default {
  props: {
    iframeLink: {
      type: String,
      required: true,
      default: "",
    },
  },

  mounted() {
    window.addEventListener("message", this.receiveMessage);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage);
  },

  methods: {
    receiveMessage(event) {
      var iframeData = event.data;

      if (iframeData.type == "im-contract-created-successfully") {
        this.$emit("enrolled", iframeData.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 10%;
  padding: 1rem;
  z-index: 1001;
  color: white;
  border-radius: 100%;
  background: #525252;
  cursor: pointer;

  @media (max-width: 800px) {
    top: 1%;
    left: 5%;
    padding: 0.7rem;
  }
}

.iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 13px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 13px;
    border: 1px solid transparent;
    background-clip: content-box;
    box-shadow: inset 0 0 0 10px #c8c8c8;
  }
}
</style>
