<template>
  <div>
    <b-modal
      id="bv-create_opportunity_modal_inside_task"
      class="modal-dialog"
      hide-footer
      hide-header
    >
      <OpportunityForm
        @closed="hideModal('bv-create_opportunity_modal_inside_task')"
        @saved="refreshOpportunityListEvent()"
      />
    </b-modal>

    <b-modal
      id="bv-edit_task_opportunity"
      class="modal-dialog"
      hide-footer
      hide-header
    >
      <OpportunityForm
        @closed="hideModal('bv-edit_task_opportunity')"
        @saved="refreshCurrentTask()"
        :id="opportunityIdFromTask"
        :isUpdate="true"
      />
    </b-modal>
  </div>
</template>

<script>
import OpportunityForm from "@/views/forms/OpportunityForm.vue";

export default {
  components: {
    OpportunityForm,
  },

  computed: {
    opportunityIdFromTask() {
      return this.$store.getters["bmodalEvents/getCurrentOpportunityId"];
    },
  },

  methods: {
    hideModal(modal) {
      this.$bvModal.hide(modal);
    },

    refreshOpportunityListEvent() {
      this.$store.dispatch(
        "bmodalEvents/disptachOpportunityCreationInsideOpportunitySelectEvent"
      );
    },

    refreshCurrentTask() {
      this.$store.dispatch(
        "bmodalEvents/dispatchOpportunityCreationInsideTaskEvent"
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
