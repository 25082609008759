const initialState = () => ({
  keep_filters: false,

  filters: {
    start_timestamp: null,
    end_timestamp: null,
    date_type: "updated_at",
    user: [],
    user_id: [],
  },

  date_types: [
    { id: "created_at", title: "Data de criação" },
    { id: "updated_at", title: "Data de atualização" },
  ],

  redirectionParams: {},

  comparativeChartOptions: [],

  defaultQueries: null,
});

const getters = {
  getFilters(state) {
    return state.filters;
  },

  getDateType(state) {
    return state.date_types;
  },

  getComparativeChartOptions(state) {
    return state.comparativeChartOptions;
  },

  getDefaultQueries(state) {
    return state.defaultQueries;
  },

  getRedirectionParams(state) {
    return state.redirectionParams;
  },

  getKeepFilters(state) {
    return state.keep_filters;
  },

  haveAnyActiveFilters(state) {
    if (state.filters.start_timestamp) {
      return true;
    }

    if (state.filters.end_timestamp) {
      return true;
    }

    if (state.filters.user_id.length) {
      return true;
    }

    return false;
  },
};

const actions = {
  resetState({ state }) {
    const initial = initialState();

    if (!state.keep_filters) {
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  cleanValues({ state }) {
    if (!state.keep_filters) {
      state.filters = {
        start_timestamp: null,
        end_timestamp: null,
        date_type: "updated_at",
        user: [],
        user_id: [],
      };

      return state.filters;
    }
  },

  clearRedirectionFilter({ state }) {
    state.redirectionParams = {};
  },

  setRedirectionFilters({ state }, filters) {
    state.redirectionParams = filters;
  },

  setFilters({ state }, filters) {
    state.filters = filters;
  },

  setComparativeChartOptions({ state }, comparativeChartOptions) {
    state.comparativeChartOptions = comparativeChartOptions;
  },

  setDefaultQueries({ state }, defaultQueries) {
    state.defaultQueries = defaultQueries;
  },

  setKeepFilters({ state }, keep_filters) {
    state.keep_filters = keep_filters;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};
