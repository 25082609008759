<template>
  <div @click="changeDropState()" class="tags-selector">
    <div class="selector-title">Etiquetas</div>

    <div v-if="hasTagsRequest" class="spinner-holder">
      <b-spinner class="loading-spinner" />
    </div>

    <div v-if="value.length && !hasTagsRequest" class="tags-holder">
      <div v-for="(tag, index) in value" :style="getTagStyle(tag)" :key="index" class="tag">
        {{ getTagName(tag) }}
      </div>
    </div>

    <b-button v-else-if="!hasTagsRequest" class="add-button" variant="outline-primary">
      Adicionar tag +
    </b-button>

    <div
      v-if="showDrop"
      @click.stop
      class="selector-drop"
      v-click-outside="() => (showDrop = false)"
    >
      <div v-for="(tag, index) in tags" :key="index" class="drop-option">
        <input
          @change="selectTag(tag)"
          :disabled="value.length >= 5 && !tag.selected"
          type="checkbox"
          class="checkbox"
          v-model="tag.selected"
        />

        <div :style="`background: #${tag.color}`" class="color-dot" />

        <div class="tag-name">
          {{ tag.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import { errorHandling } from "@/mixins";

export default {
  mixins: [errorHandling],

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    BButton,
    BSpinner,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tags: [],

      showDrop: false,
      hasTagsRequest: false,
    };
  },

  created() {
    this.fetchTags();
  },

  methods: {
    updateValue(newValue) {
      this.$emit("input", newValue);
    },

    changeDropState() {
      this.showDrop = !this.showDrop;
    },

    getTagName(tagId) {
      const tag = this.tags.find((tag) => tag.id === tagId);

      return tag?.name;
    },

    getTagStyle(tagId) {
      const color = this.tags.find((tag) => tag.id === tagId)?.color;

      if (!color) return "";

      const rgb = parseInt(color, 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = rgb & 0xff;

      const luminance = (0.2126 * r) / 255 + (0.7152 * g) / 255 + (0.0722 * b) / 255;

      const contrastColor = luminance > 0.8 ? "000000" : "ffffff";
      const borderColor = luminance > 0.8 ? "d1d1d1" : color;

      return `background: #${color}; border: 1px solid #${borderColor}; color: #${contrastColor}`;
    },

    selectTag(tag) {
      if (tag.selected) {
        this.updateValue([...this.value, tag.id]);

        return;
      }

      const newValue = this.value.filter((tagId) => tagId !== tag.id);

      this.updateValue(newValue);
    },

    fetchTags() {
      this.hasTagsRequest = true;

      this.$store
        .dispatch("opportunityStore/fetchTags")
        .then((response) => {
          const data = response?.data;

          if (data?.length) {
            data.forEach((element) => {
              element.selected = this.value.includes(element.id);
            });
          }

          this.tags = data;
        })
        .catch(() => {
          this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
        })
        .finally(() => {
          this.hasTagsRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-selector {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 13px 1rem 10px 1rem;
  gap: 5px;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  cursor: pointer;

  .spinner-holder {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .selector-title {
    position: absolute;
    top: -9px;
    left: 25px;
    font-weight: 600;
    font-size: 12px;
    padding: 0 5px;
    color: #4c5862;
    background: #ffffff;
  }

  .tags-holder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5px;
    overflow: hidden;

    .tag {
      text-overflow: ellipsis;
      word-wrap: break-word;
      font-size: 12px;
      max-width: 100%;
      padding: 5px 10px;
      border-radius: 3px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .add-button {
    font-size: 12px;
    padding: 5px 10px !important;
    border-radius: 3px;
    border: solid 1px #046af3 !important;
    color: #046af3;
  }

  .selector-drop {
    display: flex;
    flex-direction: column;
    position: absolute;
    max-height: 250px;
    width: 100%;
    top: calc(100% + 4px);
    left: 0;
    padding: 10px;
    gap: 5px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    overflow-x: hidden;

    .drop-option {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 15px;

      .checkbox {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: none;
      }

      .color-dot {
        min-width: 20px;
        min-height: 20px;
        border-radius: 50%;
        border: solid 1px #d2d2d2;
        outline: none;
      }

      .tag-name {
        font-size: 14px;
        color: #4c5862;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      margin: 4px 0;
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 5px #83a3d3;
    }
  }
}
</style>
