<template>
  <div class="opportunity-description">
    <div style="padding: 0 10px">
      <h3 class="text-center pt-2 px-2 pb-2">Escreva uma observação</h3>

      <b-form-textarea
        @change="checkCharacterLimit"
        @click="isSelected = true"
        @blur="handleBlur"
        :class="isSelected ? 'selected-box' : ''"
        class="mt-1 input-box"
        placeholder="Observação 💬"
        rows="5"
        v-model="description"
      />

      <div v-show="isSelected" class="save-button">
        <b-button @click="save(true)" variant="light">Deletar</b-button>

        <b-button @click="save()" variant="primary">Salvar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BFormTextarea } from "bootstrap-vue";
import { errorHandling } from "@/mixins";

export default {
  mixins: [errorHandling],

  components: {
    BButton,
    BFormTextarea,
  },

  data() {
    return {
      description: this.opportunity.description,
      hasSaveRequest: false,
      isSelected: false,
      maxCharacters: 10000,
    };
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: null,
    },

    fromOmnichannelDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    checkCharacterLimit() {
      if (this.description.length > this.maxCharacters) {
        this.description = this.description.slice(0, this.maxCharacters);
      }
    },

    handleBlur() {
      setTimeout(() => {
        if (!this.hasSaveRequest) {
          this.isSelected = false;
        }
      }, 100);
    },

    save(reset = false) {
      if (this.hasSaveRequest) {
        return;
      }

      this.hasSaveRequest = true;

      const updatedOpportunity = {
        id: this.opportunity.id,
        description: reset ? "" : this.description,
      };

      this.$store
        .dispatch("opportunityStore/save", updatedOpportunity)
        .then(() => {
          if (this.fromOmnichannelDesktop) {
            this.$store.commit("omnichannel/UPDATE");
          } else {
            this.$emit("saved");
          }

          this.hasSaveRequest = false;
          this.isSelected = false;
        })
        .catch((error) => {
          this.hasSaveRequest = false;

          console.log(error);

          this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.opportunity-description {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .input-box {
    border-radius: 6px;
    border: solid 1px #c4ccd8;
  }

  .selected-box {
    border: solid 1px #046af3;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .save-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 7px 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: solid 1px #046af3;
  }
}
</style>
