<template>
  <div
    class="chat-button"
    :class="{ 'active': active }"
  >
    <i :class="icon" />

    <p v-if="active">
      {{ text }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'ChatButton',

    data() {
      return {
        active: false,
      };
    },

    props: {
      icon: {
        type: String,
        default: 'icon-pencil-line'
      },

      text: {
        type: String,
        default: 'Edit'
      }
    },

    mounted() {
      setTimeout(() => {
        this.active = true;
      }, 1500);
    }
  };
</script>

<style lang="scss" scoped>
  .chat-button {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease-out;
    border-radius: 10px 10px 10px 0px;
    
    &.active {
      background: #046AF3;

      i {
        color: #FFF;
      }

      p {
        color: #FFF;
        font-weight: 400;
      }
    }

    i {
      font-size: 1.5rem;
      color: #046AF3;
    }

    p {
      font-size: 12px;
      margin-left: 0.5rem;
      margin-bottom: 0 !important;
    }
  }
  .chat-button:hover {
    background: #4594FF !important;
  }
</style>
