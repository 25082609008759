<template>
  <div
    :style="`justify-content: ${message.user_id || message.bot_name ? ' right' : ' left'}`"
    class="timer-check"
  >
    {{ formatMessageDate(message.sent_at, message.user_id || message.bot_name) }}

    <feather-icon
      v-if="message.user?.name || message.bot_name"
      :icon="getIconByStatus(message.status)"
      :color="message.status == 'Read' ? '#046af3' : '#adb5bd'"
      size="10"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formatMessageDate(date) {
      const momentDate = moment.parseZone(date);

      return momentDate.format("HH:mm");
    },

    getIconByStatus(status) {
      if (status == "Delivered" || status == "Read") {
        return "CheckCircleIcon";
      }

      return "CheckIcon";
    },
  },
};
</script>

<style lang="scss" scoped>
.timer-check {
  display: flex;
  align-items: center;
  font-size: 10px;
  width: 100%;
  gap: 5px;
  margin: -4px 0 -6px 0;
  color: #adb5bd;
}
</style>
