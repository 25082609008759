var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contract-status"},[_c('div',{staticClass:"contract-top-row",style:({
      borderLeft:
        '5px solid ' + _vm.getPaymentStatusColor(_vm.contract.payment_status),
    })},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('i',{staticClass:"icon-upload-file",staticStyle:{"font-size":"30px","margin-right":"15px"},style:({
          color: _vm.getPaymentStatusColor(_vm.contract.payment_status),
        })}),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('span',[_vm._v(" Contrato "),_c('b',[_vm._v("#"+_vm._s(_vm.contract.id))])]),_c('span',[_vm._v(" Criado em "+_vm._s(_vm.getContractDatas(_vm.contract.created_at))+" ")])])]),_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})],1),_c('div',{staticClass:"second-row"},[_c('i',{class:_vm.getPaymentStatusIcon(_vm.contract.payment_status),staticStyle:{"font-size":"24px","margin-right":"14px"},style:({
        color: _vm.getPaymentStatusColor(_vm.contract.payment_status),
      })}),_c('div',{staticClass:"enroll-status",staticStyle:{"margin-right":"8px"}},[_c('div',[_c('b',[_vm._v(" "+_vm._s(_vm.getPaymentStatus(_vm.contract.payment_status) + ":")+" ")]),_c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v(" "+_vm._s(_vm.$service.formatBrlAmountSymbol(_vm.contract.enrollment_fee))+" ")])]),_c('span',[_vm._v(" "+_vm._s("(Venc. " + _vm.$service.dateFormat( _vm.contract.payment_due_date, "YYYY-MM-DD HH:mm:ss", "DD/MM/YY" ) + ")")+" ")])])]),(_vm.dependent.isResponsable)?_c('div',{staticClass:"third-row"},[_c('b',[_vm._v("Dependente")]),_c('span',[_vm._v(" "+_vm._s(_vm.dependent.studentName)+" ")]),_c('b',[_vm._v("CPF do dependente")]),_c('span',[_vm._v(" "+_vm._s(_vm.dependent.studentDocument)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }