export default {
  formatter(tma) {
    if (tma < 60) {
      let tmaValue = parseInt(tma);
      return `${tmaValue.toString()}s`;
    }

    if (tma < 3600) {
      let tmaValue = parseInt(tma / 60);
      return `${tmaValue.toString()}min`;
    }

    if (tma < 86400) {
      let tmaValue = parseInt(tma / 3600);
      return `${tmaValue.toString()}h`;
    }

    if (tma < 31536000) {
      let tmaValue = parseInt(tma / 86400);
      return `${tmaValue.toString()}d`;
    }

    if (tma > 31536000) {
      let tmaValue = parseInt(tma / 31536000);
      return `${tmaValue.toString()}a`;
    }
  },

  formatterWithIndentificator(tma) {
    if (tma < 600) {
      return "acceptableTime"
    } else if (tma > 600 && tma < 1800) {
      return "attentionTime"
    } else if (tma > 1800) {
      return "exceededTime"
    }
  }
}