const initialState = () => ({
  isOpen: false,

  updateTick: false,

  newsList: [
    {
      id: "newDefaultDate",
      created_at: "01/08/2023 - 09:00",
      expiration: "08/08/2023",
      message:
        "Agora, o nosso CRM exibe somente as oportunidades dos últimos dias no Pipeline (área inicial da plataforma). Se você deseja ver todas as oportunidades, basta filtrar a data desejada.",
    },

    {
      id: "batchMessaging",
      created_at: "27/09/2024 - 09:00",
      expiration: "10/10/2024",
      message:
        "Nova funcionalidade no CRM: Disparo de Mensagens em Massa! Agora, com apenas 3 cliques e filtros avançados, você pode enviar mensagens para todos os seus clientes de forma rápida e integrada. Veja como é simples no vídeo abaixo.",
      iframeInfo: {
        title: "Disparo de mensagens em massa CRM",
        src: "https://www.youtube.com/embed/PHw86dthVF8",
      },
    },
  ],
});

const getters = {
  getAllWarnings(state) {
    return state.newsList;
  },
};

const mutations = {
  CHANGE_NEWS_MODAL_CONDITION(state, condition) {
    state.isOpen = condition;
  },

  TICK_UPDATE(state) {
    state.updateTick = !state.updateTick;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
};
